/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState } from 'react'
import { CustomText } from '../styled-components'
import { TxItem, TxItemSkeleton } from './TxRows'
import { IntersectionObserverBlock } from '../../../../components/IntersectionObserverBlock'
import { observer } from 'mobx-react-lite'
import { useStores } from '../../../../hooks/useStores'

export const TransactionList: FC = observer(() => {
  const { appTransactionsStore } = useStores()
  const { transactionsList, isLastPage } = appTransactionsStore

  const onIntersect = async () => {
    await appTransactionsStore.updateTransactionsList()
  }

  if (!transactionsList.length) {
    if (isLastPage) {
      return <CustomText size={20} textAlign={'center'}>History is empty</CustomText>
    }
    return <div>{Array(6).fill(null).map((_, index) => <TxItemSkeleton key={index} />)}</div>
  }

  return <>
    {transactionsList.map(tx => <TxItem key={tx.id} {...tx} />)}
    {isLastPage && <CustomText size={14} textAlign={'center'}>End of transactions history</CustomText>}
    {!isLastPage && transactionsList.length >= 7 &&
      <IntersectionObserverBlock
        onIntersect={onIntersect}
      />
    }
  </>
})