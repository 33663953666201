import BigNumber from 'bignumber.js'

export type AnyBNType = string | BigNumber | number

export const plus = (_x: AnyBNType, _y: AnyBNType) => new BigNumber(_x).plus(_y)
export const minus = (_x: AnyBNType, _y: AnyBNType) => new BigNumber(_x).minus(_y)

export const multiply = (_x: AnyBNType, _y: AnyBNType) => new BigNumber(_x).multipliedBy(_y)
export const divide = (_x: AnyBNType, _y: AnyBNType) => new BigNumber(_x).dividedBy(_y)

export const powp = (_x: AnyBNType, _y: AnyBNType) => {
  return new BigNumber(_x).pow(_y)
}

