import React from 'react'
import styled from 'styled-components'
import { Button } from '../../components/Button'
import { BackgroundWrapper, EastLogo } from './common/styled-components'
import { Block } from '../../components/Block'

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const ReloadButton = styled(Button)`
  width: fit-content;
  padding: 0 32px;
`

const PageTitle = styled.h1`
  font-size: 48px;
  margin: 0;
  line-height: 1;
`

const PageDescription = styled.h1`
  font-size: 16px;
  padding: 0;
`

export const PageError = () => {
  const reloadPage = () => {
    location.reload()
  }

  return <Wrapper>
    <BackgroundWrapper />
    <EastLogo />
    <PageTitle>Something went wrong</PageTitle>
    <PageDescription>It’s probably nothing serious, just reload the page</PageDescription>
    <Block marginTop={40} />
    <ReloadButton
      customType='primary'
      onClick={reloadPage}>
      Reload page
    </ReloadButton>
  </Wrapper>
}