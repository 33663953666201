import { Outlet, Route, Routes } from 'react-router-dom'
import React, { Suspense, useCallback } from 'react'
import { AccountLayout } from '../modules/account/AccountLayout'
import { routeConfig } from './routeConfig'
import { SuccessModal } from '../modules/account/modals/SuccessModal/SuccesModal'
import { AppRoutesProps } from './types'
import { RequireAuth } from './RequireAuth'
import { SUCCESS_PATH } from '../consts/routes'
import { observer } from 'mobx-react-lite'
import { MobileOnly } from './MobileOnly'

const RouteWithProviders = ({ route }: {route: AppRoutesProps}) => {
  const routeWithAuth = route.authOnly ?
    <RequireAuth>
      {route.element}
    </RequireAuth> :
    route.element

  return route.mobileOnly ? <MobileOnly>{routeWithAuth}</MobileOnly> : routeWithAuth
}

export const AppRouter = observer(() => {
  const renderWithWrapper = useCallback((route: AppRoutesProps) => {
    const element = (
      <Suspense fallback={route.fallbackLoader || 'Loading...'}>
        <Outlet />
        <RouteWithProviders route={route} />
      </Suspense>
    )

    return (
      <Route key={route.path} element={!route.withoutLayout && <AccountLayout />}>
        <Route
          path={route.path}
          element={element}
        >
          {route.withSuccessPath &&
            <Route
              path={SUCCESS_PATH}
              element={<SuccessModal />}
            />
          }
        </Route>
      </Route>
    )
  }, [])
  return <Routes>
    {Object.values(routeConfig).map(renderWithWrapper)}
  </Routes>
})