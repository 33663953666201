import { makeAutoObservable, runInAction } from 'mobx'
import { Api } from '../api'
import { showErrorToast, IThrowNotification } from '../common/helpers'
import { AssetName, EastOpType } from '../types/interfaces'
import dayjs from 'dayjs'
import { TRANSACTIONS_POLLING_INTERVAL_MS } from '../consts/constants'
import * as Sentry from '@sentry/react'
import { TNodeInvokeScriptTransactionInfo, TNodeTransferTransactionInfo } from '../api/ApiInterfaces'
import WavesConfigStore from './waves/WavesConfigStore'

const PENDING_TXS_LS_KEY = 'pending_txs'

type TPendingTxData = {
  id: string,
  type: EastOpType,
  timestamp: number,
  address: string,
  assetName: AssetName,
}

type IWatchTxParams = {
  tx: TPendingTxData,
  onResultReceived: () => void,
  onErrorReceived: (data: IThrowNotification) => void,
}


type LSPendingTxs = Record<string, Array<Omit<TPendingTxData, 'address'>>>

export class AbortWatcherError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'AbortError'
  }
}

const PendingStatusLimitMS = 10 * 60 * 1000 // 10 minutes

export default class AppTransactionsStore {
  private api: Api
  private wavesConfigStore: WavesConfigStore

  private address = ''
  transactionsList: Array<TNodeInvokeScriptTransactionInfo | TNodeTransferTransactionInfo> = []
  isLastPage = false
  lastTxId: string | undefined
  txRequests: TPendingTxData[] = []
  private pollSingleTxDataId: NodeJS.Timeout | null = null

  showTransactions = true

  constructor(api: Api, wavesConfigStore: WavesConfigStore) {
    this.api = api
    this.wavesConfigStore = wavesConfigStore
    makeAutoObservable(this)
  }

  clearTransactionsList() {
    this.transactionsList = []
    this.lastTxId = undefined
    this.isLastPage = false
  }

  getFilteredTxs = (tx: TNodeInvokeScriptTransactionInfo | TNodeTransferTransactionInfo) => {
    if (tx.type === 16 && this.wavesConfigStore.allContractIds.includes(tx.dApp)) {
      return tx
    }
    if (tx.type === 4 && tx.assetId === this.wavesConfigStore.eastAssetId) {
      return tx
    }
  }

  updateTransactionsList = async () => {
    try {
      const transactionsList = await this.api.getTransactionsList(
        this.address,
        this.lastTxId,
      )
      if (!transactionsList.length) {
        runInAction(() => {
          this.isLastPage = true
        })
        return
      }
      const filtered = transactionsList.filter(this.getFilteredTxs)
      runInAction(() => {
        this.transactionsList = [...this.transactionsList, ...filtered]
        this.lastTxId = transactionsList[transactionsList.length - 1].id
      })
      if (this.transactionsList.length < 10 || !filtered.length) {
        await this.updateTransactionsList()
      }
    } catch (e: any) {
      console.error('Can not update transactions list', e.message)
    }
  }

  setShowTransactions = (value: boolean) => {
    this.showTransactions = value
  }

  startWatchTxsForNewAddress(address: string) {
    this.address = address
    this.clearTxRequests()
    this.startWatchPollingTxs(address)
  }

  async startWatchPollingTxs(address: string) {
    const txs = this.getPendingTxFromLS()[address] || []
    const pollingTxs = txs.map(tx => this.watchTxStatus({ ...tx, address }))
    await Promise.all(pollingTxs)
  }

  clearTxRequests() {
    if (this.pollSingleTxDataId) {
      clearInterval(this.pollSingleTxDataId)
    }
    runInAction(() => {
      this.txRequests = []
    })
  }

  private setPendingTxToLS(tx: TPendingTxData) {
    const { address, ...txData } = tx
    const txs = this.getPendingTxFromLS()
    const updatedTxs = {
      ...txs,
      [address]: [txData],
    }
    localStorage.setItem(PENDING_TXS_LS_KEY, JSON.stringify(updatedTxs))
  }

  private getPendingTxFromLS(): LSPendingTxs {
    const txs = localStorage.getItem(PENDING_TXS_LS_KEY)
    if (txs) {
      return JSON.parse(txs) as LSPendingTxs
    }
    return {}
  }

  private clearPendingTxsByAddress(address: string) {
    const prevTxs = this.getPendingTxFromLS()
    const updatedTxs = {
      ...prevTxs,
      [address]: [],
    }
    localStorage.setItem(PENDING_TXS_LS_KEY, JSON.stringify(updatedTxs))
  }

  async watchTxStatus(tx: TPendingTxData) {
    this.setPendingTxToLS(tx)

    const { id, type } = tx
    console.log('Start watching tx status:', id, type)
    // Support only one tx status at the moment
    runInAction(() => {
      this.txRequests = [tx]
    })

    const onResultReceived = () => {
      console.log(`Tx '${id}' (${type}) successfully mined`)
      this.clearTxRequests()
      this.clearPendingTxsByAddress(tx.address)
    }

    const onErrorReceived = (data: IThrowNotification) => {
      showErrorToast(data)
      this.clearTxRequests()
      this.clearPendingTxsByAddress(tx.address)
      Sentry.captureException(data.title)
    }

    try {
      const params: IWatchTxParams = {
        tx,
        onResultReceived,
        onErrorReceived,
      }
      await this.watchTx(params)
    } catch (e: any) {
      console.error('Watch tx status error:', e.message)
    }
  }

  async watchTx(params: IWatchTxParams) {
    try {
      const tx = await this.waitForNodeReceiveTx(this.api, params.tx)
      if (tx) {
        params.onResultReceived()

        this.clearTransactionsList()
        await this.updateTransactionsList()
      }
    } catch (e: any) {
      params.onErrorReceived(e)
    }
  }

  waitForNodeReceiveTx(
    api: Api,
    tx: TPendingTxData,
  ): Promise<TNodeInvokeScriptTransactionInfo | TNodeTransferTransactionInfo> {
    return new Promise((resolve, reject) => {
      let attempt = 0
      this.pollSingleTxDataId = setInterval(() => {
        attempt += 1
        api.getTransactionById(tx.id)
          .then((completedTx) => {
            console.log(`Polling tx '${tx.id}' (${tx.type} ${tx.assetName}) from node, attempt: ${attempt}`)
            if (completedTx) {
              resolve(completedTx)
            }
          })
          .catch((e: any) => {
            console.error(`Can not get ${tx.type} transaction, ${e.message}`)
            if (dayjs().valueOf() - dayjs(tx.timestamp).valueOf() > PendingStatusLimitMS) {
              reject({
                title: `Timeout exeeded for ${tx.type} transaction`,
                message: `Can not get tx: timeout exceeded (${Math.floor(PendingStatusLimitMS / (60 * 1000))} minutes)`,
              })
            }
          })
      }, TRANSACTIONS_POLLING_INTERVAL_MS)
    })
  }
}