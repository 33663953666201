import { observer } from 'mobx-react-lite'
import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useStore } from '../hooks/useStores'
import { getRouteConnectWallet } from '../consts/routes'

type ProtectedRouteProps = {
  children: JSX.Element,
}

export const RequireAuth = observer(({ children }: ProtectedRouteProps) => {
  const wavesSignStore = useStore('wavesSignStore')
  const { address } = wavesSignStore
  const location = useLocation()
  const state = { ...location.state, from: location.pathname }

  if (!address) {
    return <Navigate to={getRouteConnectWallet()} state={state} />
  }

  return children
})