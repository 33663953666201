/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { getRouteClosePosition, getRouteGetOvercollateral, getRouteMain, getRouteSupply } from '../../../../consts/routes'
import { abbreviateNumber, cutNumber, getAssetAmountFloat, getEastAmountFloat, roundNumber } from '../../../../common/utils'
import { getCollateralLevelName } from './helpers'
import { CloseButton, Container, DetailedButtonsContainer, DetailedCardWrapper, DetailsBody, DisconnectButton, PrimaryText, QuestionTooltipContainer, ReissueButton, SupplyButton, Text } from './styled-components'
import { FlexWrapper, SpaceBetweenWrapper } from '../../common/styled-components'
import { CardFooter } from './CardFooter'
import { useStores } from '../../../../hooks/useStores'
import { Block } from '../../../../components/Block'
import { TokenBalance } from '../TokenBalance/TokenBalance'
import { ColumnWrapper } from '../../../exchangeEast/styled-components'
import { Button } from '../../../../components/Button'
import questionIcon from '../../../../resources/images/icon-question-fill.svg'
import { CopyIcon, Icon } from '../../../../components/icon'
import { Tooltip } from '../../Menu/styled-components'
import { useClaimAndStakeOrient } from './useClaimAndStakeOrient'
import { getTrimmedAssetAmount } from '../../../../common/helpers'
import { AddressWithCopyBlock } from '../../../../components/AddressWithCopyBlock'
import { useStoreManager } from '../../../../stores/useStoreManager'

type DetailedCardProps = {
  isShown: boolean,
  onClick: () => void,
  showFadeIn: boolean,
}

export const DetailedCard = observer((props: DetailedCardProps) => {
  const { wavesCalculationsStore, wavesSignStore, wavesVaultStore, wavesBalancesStore } = useStores()
  const navigate = useNavigate()
  const levelName = getCollateralLevelName(
    wavesCalculationsStore.vaultBackingRatioPercent,
    wavesCalculationsStore.vaultLiquidationRatioPercent,
  )
  const vaultEastProfit = getEastAmountFloat(wavesVaultStore.vaultEastProfit)
  const isVaultHasCollateralAssets = Boolean(wavesVaultStore.nonZeroCollateralAssets.length)
  const orientClaimableAmount = getTrimmedAssetAmount(wavesVaultStore.orientClaimableAmount)
  const { sendClaimAndStakeOrient } = useClaimAndStakeOrient()
  const { onDisconnectProvider } = useStoreManager()

  const onClaimOrientClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    await sendClaimAndStakeOrient()
  }

  const onChangeAccountClick = async () => {
    await wavesSignStore.logout()
    onDisconnectProvider()
    navigate(getRouteMain())
  }

  return <Container
    isShown={props.isShown}
    showFadeIn={props.showFadeIn}
  >
    <DetailsBody isShown={props.isShown}>
      <DetailedCardWrapper>
        <div>
          <AddressWithCopyBlock noBg fontSize={16} address={wavesSignStore.address} />
          <Block marginTop={5} />
          <SpaceBetweenWrapper>
            <Text>current address</Text>
            <DisconnectButton
              size='small'
              onClick={onChangeAccountClick}
            >
              Change account
            </DisconnectButton>
          </SpaceBetweenWrapper>
        </div>
        <FlexWrapper gap={20}>
          <ColumnWrapper>
            <TokenBalance integerPartSize={18}>{orientClaimableAmount}</TokenBalance>
            <Text fontSize={16}>Orient Claimable</Text>
          </ColumnWrapper>
          {/* <Button
            size='small'
            customType='primary'
            onClick={onClaimOrientClick}
            disabled
          >
            Claim & Stake
          </Button>
          <QuestionTooltipContainer>
            <Tooltip>
              Your ORIENT gets locked after staking. The locking period starts over every time you stake more.
            </Tooltip>
            <Icon backgroundImage={questionIcon} size={18} />
          </QuestionTooltipContainer> */}
        </FlexWrapper>
        <DetailedButtonsContainer>
          <SupplyButton
            levelName={levelName}
            size={'small'}
            data-attr={'cardBack_addWest'}
            onClick={(e: any) => {
              e.stopPropagation()
              navigate(getRouteSupply())
            }}
            disabled={!isVaultHasCollateralAssets}
          >
              Supply
          </SupplyButton>
          {+vaultEastProfit >= 0.1 &&
              <ReissueButton
                size={'small'}
                data-attr={'cardBack_issueEast'}
                onClick={(e: any) => {
                  e.stopPropagation()
                  navigate(getRouteGetOvercollateral() + '/reissue')
                }}
              >
                {`Issue ${+vaultEastProfit < 1000 ? roundNumber(vaultEastProfit, 2) :
                  abbreviateNumber(+cutNumber(vaultEastProfit, 0))} free EAST`}
              </ReissueButton>
          }
          <CloseButton
            size={'small'}
            data-attr={'menu_vault'}
            onClick={(e) => {
              e.stopPropagation()
              navigate(getRouteClosePosition())
            }}
            disabled={!isVaultHasCollateralAssets}
          >
            Close Vault
          </CloseButton>
        </DetailedButtonsContainer >
      </DetailedCardWrapper >
    </DetailsBody >
    <CardFooter isShown={props.isShown} onClick={props.onClick} />
  </Container >

})