import styled from 'styled-components'
import { devices } from './devices'

export const Block = styled.div<{ marginTop: number | string, mobileMarginTop?: number | string}>`
  margin-top: ${({ marginTop = 0 }) => typeof marginTop === 'number' ? `${marginTop}px` : marginTop};

  @media ${devices.mobile} {
    margin-top: ${({ mobileMarginTop }) => typeof mobileMarginTop === 'number' ? `${mobileMarginTop}px` : mobileMarginTop};
  }
`

export const BlockCenter = styled(Block)`
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
`

export const RelativeContainer = styled.div`
  position: relative;
  width: 100%;
`
