import React from 'react'
import styled from 'styled-components'
import spinner from '../resources/images/spinner.svg'
import { PrimaryModal } from './modal/Modal'
import { rotate } from './Animations'
import { SpaceBetweenWrapper } from '../modules/account/common/styled-components'

const ModalLoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
`

const ModalSpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${rotate} .75s linear infinite;
  margin-right: 15px;
`

const PageSpinnerWrapper = styled.div`
  height: 100vh;
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;
`

export const PageLoader = () => (
  <PageSpinnerWrapper>
    <img src={spinner} />
  </PageSpinnerWrapper>
)

export const ModalSpinner = () => (
  <ModalLoaderWrapper>
    <SpaceBetweenWrapper>
      <ModalSpinnerWrapper><img src={spinner} /> </ModalSpinnerWrapper>
      Loading...
    </SpaceBetweenWrapper>
  </ModalLoaderWrapper>
)

export const ModalLoader = () => {
  return <PrimaryModal>
    <ModalSpinner />
  </PrimaryModal>
}