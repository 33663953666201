import styled from 'styled-components'
import React from 'react'
import { rotate } from './Animations'

export type ISpinnerProps = {
  color?: string,
}

export const Spinner = styled.div<ISpinnerProps>`
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
  border: 2px solid;
  border-color: ${props => props.color ? props.color : props.theme.secondaryColor};
  border-right-color: transparent;
  border-radius: 60%;
  animation: ${rotate} .75s linear infinite;  
`

export const BeforeText = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 10px;
`

export const ButtonSpinner = () => {
  return <BeforeText>
    <Spinner />
  </BeforeText>
}


