import { makeAutoObservable, runInAction } from 'mobx'
import { IRate, IRates } from '../types/interfaces'
import { Api } from '../api'
import { ORACLE_DECIMALS, RATES_POLLING_INTERVAL_MS, WAVES_KEYS_SEPARATOR } from '../consts/constants'
import { divp } from '@wavesenterprise/east-math'
import WavesConfigStore from './waves/WavesConfigStore'
import { getContractKey } from '../common/helpers'
import { PromiseFulfilledResult } from '../types'

const getNormalizedRates = (rates: IRate[]): IRates => {
  return rates.reduce((acc, rate) => ({
    ...acc,
    [rate.assetId]: {
      price: rate.price,
      name: rate.assetName,
    },
  }), {} as IRates)
}

export default class AppRatesStore {
  private api: Api
  private wavesConfigStore: WavesConfigStore

  private pollingRatesId: NodeJS.Timeout | null = null

  rates: IRates = {}

  constructor(api: Api, wavesConfigStore: WavesConfigStore) {
    makeAutoObservable(this)
    this.api = api
    this.wavesConfigStore = wavesConfigStore
  }

  getRateByAssetId(assetId: string) {
    return divp(this.rates[assetId]?.price || 0, ORACLE_DECIMALS)
  }

  startPollingRates = async () => {
    this.stopPollingRates()

    await this.setRates()

    runInAction(() => {
      this.pollingRatesId = setInterval(async () => {
        await this.setRates()
      }, RATES_POLLING_INTERVAL_MS)
    })
  }

  stopPollingRates = () => {
    if (this.pollingRatesId) {
      clearInterval(this.pollingRatesId)
    }
  }

  private async getRates() {
    try {
      const mapOracleIdToAssets = Object.entries(this.wavesConfigStore.assets)
        .reduce((acc, [assetId, assetData]) => {
          const oracleId = assetData.oracleAddress
          const assets = acc[oracleId] || []
          assets.push(getContractKey('%s%s', 'price', this.wavesConfigStore.assets[assetId].ticker))
          return {
            ...acc,
            [oracleId]: assets,
          }
        }, {} as Record<string, string[]>)
      const promises = Object.entries(mapOracleIdToAssets).map(([address, tickers]) => {
        return this.api.getWavesContractDataByKeys(address, tickers)
          .then(data => data.map(el => {
            const splittedKey = el.key.split(WAVES_KEYS_SEPARATOR)
            const ticker = splittedKey[splittedKey.length - 1]
            const { assetName, assetId } = this.wavesConfigStore.getAssetsDataMapByKey('ticker')[ticker]
            return {
              assetId,
              price: el.value,
              assetName,
            }
          }))
      })
      const result = await Promise.allSettled(promises)
      const fullfilledResult = result
        .filter(({ status }) => status === 'fulfilled')
        .map(p => (p as PromiseFulfilledResult<IRate[]>).value)
        .flat()
      return fullfilledResult
    } catch (e: any) {
      console.error('Error on getting prices', e.message)
      return []
    }
  }

  private async setRates() {
    const prices = await this.getRates()
    const normalizedRates = getNormalizedRates(prices)
    runInAction(() => {
      this.rates = normalizedRates
    })
  }
}