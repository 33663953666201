import styled, { css } from 'styled-components'
import { devices } from '../devices'
import { ModalStatus } from './Modal'
import { BackgroundWrapper } from '../../modules/account/common/styled-components'

export const containerRadius = '24px'

export const Overlay = styled(BackgroundWrapper)`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;

  @media ${devices.mobile} {
    background-color: rgba(0, 0, 0, 0.5);
  }
`

export const PrimaryModalContainer = styled.div`
  background: ${props => props.theme.primaryBgColor};
  backdrop-filter: blur(9px);
  box-shadow: ${props => `0px 4px 72px ${props.theme.black15}`};
  border-radius: ${containerRadius};
  overflow: hidden;
  box-shadow: 0px 79px 32px rgba(51, 0, 18, 0.01), 0px 44px 27px rgba(51, 0, 18, 0.05), 0px 20px 20px rgba(51, 0, 18, 0.09), 0px 5px 11px rgba(51, 0, 18, 0.1), 0px 0px 0px rgba(51, 0, 18, 0.1);

  @media ${devices.mobile} {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
  width: min(466px, 90vw);
  transform: translate(-50%, -50%);

  @media ${devices.mobile} {
    top: unset;
    bottom: 0;
    left: 0;
    transform: none;
    width: 100vw;
  }
`

export const PrimaryModalHeader = styled.div<{ status?: ModalStatus }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72px;
  font-family: BrutalType, sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-transform: uppercase;

  color: ${props => props.theme.primaryColor};

  @media ${devices.mobile} {
    padding: 0 50px;
    text-align: center;
  }
`

export const PrimaryModalContent = styled.div<{gap?: number}>`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: ${props => (props.gap) + 'px'};

  @media ${devices.mobile} {
    padding: 24px 16px;
  }
`

export const PrimaryModalFooter = styled.div`
  display: flex;
  padding: 16px 24px 24px;
  gap: 10px;
  background: ${props => props.theme.secondaryBgColor};
`

export const IconContainerHeader = styled.div`
  position: absolute;
  top: 0;
  right: 16px;
  bottom: 0;
  display: flex;
  align-items: center;
  transition: transform 250ms;
  z-index: 10;
  
  :hover {
    transform: scale(1.1);
  }
`

export const PrimaryModalWrapper = styled.div<{ withFooter?: boolean }>`
  @media ${devices.mobile} and (orientation: landscape) {
    ${(props) => props.withFooter ? css`
      max-height: calc(95vh - 95px - 72px);
    ` : css`
      max-height: calc(95vh - 72px);
    `}
    overflow: scroll;
  } 
`

