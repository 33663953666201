import React from 'react'
import { Container, ContentWrapper, DefaultCardInfoText } from './FrontCard/styled-components'
import { IssueEastBlock } from './FrontCard'
import { Icon } from '../../../components/icon'
import logoutIcon from '../../../resources/images/logout-icon.svg'
import styled from 'styled-components'
import { useStores } from '../../../hooks/useStores'
import { useStoreManager } from '../../../stores/useStoreManager'
import defaultCardBg from '../../../resources/images/default-card-bg.png'
import wavesCardBg from '../../../resources/images/waves-card-bg.png'
import { observer } from 'mobx-react-lite'

const LogoutButton = styled(Icon)`
  position: absolute;
  left: 26px;
  top: 26px;

  :hover {
    transform: scale(1.1);
  }
`

export const EmptyCard = observer(() => {
  const { wavesSignStore } = useStores()
  const { onDisconnectProvider } = useStoreManager()

  const onChangeAccountClick = async () => {
    await wavesSignStore.logout()
    onDisconnectProvider()
  }

  if (wavesSignStore.address) {
    return <Container isShown showFadeIn>
      <ContentWrapper bgImage={wavesCardBg}>
        <LogoutButton backgroundImage={logoutIcon} size={40} onClick={onChangeAccountClick} />
        <IssueEastBlock />
      </ContentWrapper>
    </Container>
  }

  return <Container isShown showFadeIn>
    <ContentWrapper bgImage={defaultCardBg}>
      <IssueEastBlock />
      <DefaultCardInfoText>You’ll connect your wallet in the process</DefaultCardInfoText>
    </ContentWrapper>
  </Container>
})