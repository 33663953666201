import { makeAutoObservable, onBecomeObserved, onBecomeUnobserved, runInAction } from 'mobx'
import { Api } from '../../api'
import { CollateralAssetData, IVault } from '../../types/interfaces'
import { VAULT_DATA_POLLING_INTERVAL_MS, WAVES_KEYS_SEPARATOR } from '../../consts/constants'
import { getContractKey } from '../../common/helpers'
import BigNumber from 'bignumber.js'
import { EvaluateResult } from '../../api/ApiInterfaces'
import WavesConfigStore from './WavesConfigStore'
import { divide } from '../../common/math'

type VaultInfo = Omit<IVault, 'eastAmount' | 'collateralAssets'>

export const emptyVaultInfo: VaultInfo = {
  backingRatio: '0',
  liquidationRatio: '0',
  stabilityFee: '0',
  collateralEastEquivalent: '0',
  collateralUsdEquivalent: '0',
}

export const emptyVault: IVault = {
  ...emptyVaultInfo,
  eastAmount: '0',
  collateralAssets: [],
}

const getVaultInfoValues = (vaultInfo: EvaluateResult): VaultInfo => {
  const mapKeys: Record<keyof VaultInfo, string> = {
    collateralUsdEquivalent: '_1',
    collateralEastEquivalent: '_2',
    backingRatio: '_3',
    liquidationRatio: '_4',
    stabilityFee: '_5',
  }
  const data = vaultInfo.result.value._2.value
  const result = Object.keys(mapKeys).reduce((acc, cur) => {
    const key = mapKeys[cur as keyof VaultInfo]
    return {
      ...acc,
      [cur]: data?.[key]?.value?.toString() || '0',
    }
  }, {} as VaultInfo)
  return result
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ORIENT_BALANCE_KEY = 'orientBalance'

export default class WavesVaultStore {
  private api: Api
  private wavesConfigStore: WavesConfigStore

  private pollingVaultDataId: NodeJS.Timeout | null = null
  private pollingClaimableOrientDataId: NodeJS.Timeout | null = null

  private address = ''
  vault = emptyVault

  orientClaimableAmount = '0'

  constructor(api: Api, wavesConfigStore: WavesConfigStore) {
    makeAutoObservable(this, {
      startPollingVaultData: false,
      startPollingClaimableOrient: false,

      stopPollingByIntervalId: false,
    })
    this.api = api
    this.wavesConfigStore = wavesConfigStore

    onBecomeObserved(this, 'vault', this.startPollingVaultData)
    onBecomeUnobserved(this, 'vault', () => this.stopPollingByIntervalId(this.pollingVaultDataId))

    onBecomeObserved(this, 'orientClaimableAmount', this.startPollingClaimableOrient)
    onBecomeUnobserved(this, 'orientClaimableAmount', () => this.stopPollingByIntervalId(this.pollingClaimableOrientDataId))
  }

  reset() {
    this.vault = emptyVault
    this.orientClaimableAmount = '0'
    this.stopPollingByIntervalId(this.pollingVaultDataId)
    this.stopPollingByIntervalId(this.pollingClaimableOrientDataId)
  }

  setSelectedAddress(address: string) {
    this.address = address
  }

  async setVaultData() {
    const results = await Promise.allSettled([
      this.getCollateralAssets(),
      this.getVaultEastAmount(),
      this.getVaultInfo(),
    ])
    const [collateralAssets, eastAmount, vaultInfo] = results
    runInAction(() => {
      if (collateralAssets.status === 'fulfilled') {
        this.vault.collateralAssets = collateralAssets.value
      } else {
        console.error(`Can not get collateral assets: ${collateralAssets.reason }`)
      }
      if (eastAmount.status === 'fulfilled') {
        this.vault.eastAmount = eastAmount.value
      } else {
        if (eastAmount.reason?.response?.status === 404) {
          this.vault.eastAmount = '0'
        }
        console.error(`Can not get vault east amount: ${eastAmount.reason}`)
      }
      if (vaultInfo.status === 'fulfilled') {
        this.vault = {
          ...this.vault,
          ...vaultInfo.value,
        }
      } else {
        if (vaultInfo.reason.message.includes('Vault is not exist')) {
          this.vault = {
            ...this.vault,
            ...emptyVaultInfo,
          }
        }
        console.error(`Can not get getVaultInfo() result: ${vaultInfo.reason.message}`)
      }
    })
  }

  startPollingVaultData = async () => {
    this.stopPollingByIntervalId(this.pollingVaultDataId)

    await this.setVaultData()

    runInAction(() => {
      this.pollingVaultDataId = setInterval(async () => {
        await this.setVaultData()
      }, VAULT_DATA_POLLING_INTERVAL_MS)
    })
  }

  private async setOrientClaimableAmount() {
    try {
      // const { value: amount } = await this.api.getWavesContractDataByKey(
      //   this.wavesConfigStore.orientWestingContractId,
      //   getContractKey('%s%s', ORIENT_BALANCE_KEY, this.address),
      // )
      const amount = await this.api.getOrientClaimableAmount(this.address)
      runInAction(() => {
        this.orientClaimableAmount = amount || '0'
      })
    } catch (e: any) {
      console.error('Can not get orient claimable amount:', e.message)
    }
  }

  startPollingClaimableOrient = async () => {
    this.stopPollingByIntervalId(this.pollingClaimableOrientDataId)

    await this.setOrientClaimableAmount()

    runInAction(() => {
      this.pollingClaimableOrientDataId = setInterval(async () => {
        await this.setOrientClaimableAmount()
      }, VAULT_DATA_POLLING_INTERVAL_MS)
    })
  }

  stopPollingByIntervalId = (intervalId: NodeJS.Timeout | null) => {
    if (intervalId) {
      clearTimeout(intervalId)
    }
  }

  private async getCollateralAssets(): Promise<CollateralAssetData[]> {
    const assetsKeys = Object.keys(this.wavesConfigStore.assets)
      .map(assetId => getContractKey('%s%s%s', 'vault', this.address, assetId))
    const data = await this.api.getWavesContractData(this.wavesConfigStore.eastContractId, '', assetsKeys)
    const collateralAssets = data.map(item => {
      const keyItems = item.key.split(WAVES_KEYS_SEPARATOR)
      const assetId = keyItems[keyItems.length - 1]
      const valueItems = item.value.split(WAVES_KEYS_SEPARATOR)
      const amount = valueItems[2]
      return { assetId, amount }
    })
    return collateralAssets
  }

  private async getVaultInfo(): Promise<VaultInfo> {
    const expr = { expr: `getVaultInfo("${this.address}")` }
    const result = await this.api.evaluate(this.wavesConfigStore.eastContractId, expr)
    const vaultInfo = getVaultInfoValues(result)
    return vaultInfo
  }

  private async getVaultEastAmount(): Promise<string> {
    const data = await this.api.getWavesContractDataByKey(
      this.wavesConfigStore.eastContractId,
      getContractKey('%s%s', 'vault', this.address),
    )
    const valueItems = data.value.split(WAVES_KEYS_SEPARATOR)
    const eastAmount = valueItems[2]
    return eastAmount
  }

  get vaultEastProfit() {
    const eastEq = divide(this.vault.collateralEastEquivalent, 100).decimalPlaces(0, BigNumber.ROUND_FLOOR)
    return eastEq.minus(this.vault.eastAmount).toString()
  }

  get vaultCollateralAssets() {
    return this.vault.collateralAssets
  }

  get vaultEastAmount() {
    return this.vault.eastAmount
  }

  get vaultStabilityFee() {
    return this.vault.stabilityFee
  }

  get collateralEastEquivalent() {
    return this.vault.collateralEastEquivalent
  }

  get nonZeroCollateralAssets() {
    return this.vault.collateralAssets.filter(asset => +asset.amount > 0)
  }

  getCollateralAssetAmountById(assetId: string): string {
    return this.vault.collateralAssets.find(el => el.assetId === assetId)?.amount || '0'
  }

  async getStabilityFeeFromNode(): Promise<string> {
    try {
      const { stabilityFee } = await this.getVaultInfo()
      return stabilityFee
    } catch (e) {
      console.error('Error on getting getVaultInfo', e)
      return '0'
    }
  }

  // Assets options for ui
  get nonZeroCollateralAssetsOptions() {
    return this.nonZeroCollateralAssets.map(asset => ({
      label: this.wavesConfigStore.getAssetNameById(asset.assetId).toUpperCase(),
      value: asset.assetId,
    }))
  }
}