import React, { HTMLAttributes, useRef } from 'react'
import { getCollateralLevelName } from './helpers'
import { LiquidationBlock, ProgressBarText, ProgressBarWrapper } from './styled-components'
import { useStore } from '../../../../hooks/useStores'
import { observer } from 'mobx-react-lite'
import { INFINITE_BR } from '../../../../consts/constants'

type CardFooterProps = {
  isShown: boolean,
} & HTMLAttributes<HTMLDivElement>

export const CardFooter = observer((props: CardFooterProps) => {
  const { collateralEastEquivalent, vaultEastAmount } = useStore('wavesVaultStore')
  const { vaultBackingRatioPercent,vaultLiquidationRatioPercent } = useStore('wavesCalculationsStore')
  const { current: prevVaultHealth } = useRef(+collateralEastEquivalent / +vaultEastAmount)
  const vaultBr = +vaultBackingRatioPercent > INFINITE_BR ? `> ${INFINITE_BR}` : vaultBackingRatioPercent

  const vaultHealth = +collateralEastEquivalent / +vaultEastAmount
  const progressBarFillPercent = vaultHealth === Infinity ? 1 : vaultHealth
  const levelName = getCollateralLevelName(vaultBackingRatioPercent, vaultLiquidationRatioPercent)

  return <ProgressBarWrapper
    {...props}
    prevPercent={prevVaultHealth * 100}
    percent={progressBarFillPercent * 100}
    levelName={levelName}
  >
    <ProgressBarText>
      {+vaultBackingRatioPercent > 0 ?
        <>
          <LiquidationBlock>Liquidation at <b>{vaultLiquidationRatioPercent}%</b></LiquidationBlock>
          <span>{vaultBr}%</span>
        </> :
        <div>no collateral</div>
      }
    </ProgressBarText>
  </ProgressBarWrapper>
})