import React, { FC, useRef } from 'react'
import { Sidebar, SidebarProps } from '../../../components/Sidebar'
import { EastStatsInfo } from '../common/EastStats'
import { useStore } from '../../../hooks/useStores'
import { observer } from 'mobx-react-lite'
import { useEastStats } from '../common/EastStats/useEastStats'
import { SidebarHeader } from '../common/SidebarHeader'

export const EastStatsSidebar: FC<Partial<SidebarProps>> = observer(() => {
  const { showStats, setShowStats } = useStore('appConfigStore')
  const data = useEastStats()
  const skipAnimation = useRef(!showStats)

  const toggleShowStats = () => {
    setShowStats(!showStats)
    skipAnimation.current = false
  }

  const onReload = async () => {
    await data.getStats()
  }

  return <Sidebar
    isOpen={showStats}
    skipAnimation={skipAnimation.current}
    onToggleIsOpen={toggleShowStats}
    position='right'
    title={<SidebarHeader position='right' title='East Stats' onReloadClick={onReload} />}
    hideScrollBar
  >
    <EastStatsInfo {...data} />
  </Sidebar>
})