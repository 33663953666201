import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from '../../../../components/Button'
import { Icon } from '../../../../components/icon'
import { PrimaryModal } from '../../../../components/modal/Modal'
import iconSuccess from '../../../../resources/images/success.png'
import { getRouteMain } from '../../../../consts/routes'
import { DescriptionBlock } from '../../common/styled-components'
import { EastOpTypeModalTitle } from '../../../../components/modal/EastOpTypeModalTitle'

export type SuccessModalState = {
  message: string,
  noPrevText?: boolean,
}

export const SuccessModal = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { message = '', noPrevText = false } = location.state as SuccessModalState || {}
  const pathname = location.pathname.split('/').slice(0, 2).join('/')
  const header = EastOpTypeModalTitle[pathname]

  const onClose = () => {
    navigate(getRouteMain())
  }

  return <PrimaryModal
    header={header}
    footer={
      <Button customType={'primary'} onClick={onClose}>Close this window</Button>
    }
  >
    <Icon backgroundImage={iconSuccess} size={80} />
    <DescriptionBlock style={{ color: '#1CB686' }}>
      {!noPrevText && 'The request has been created.'}<br />
      {message}
    </DescriptionBlock>
    <div />
  </PrimaryModal>
}
