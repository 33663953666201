import React from 'react'
import { observer } from 'mobx-react-lite'
import styled, { keyframes } from 'styled-components'
import { OpTypeName } from '../../../types/interfaces'
import iconClock from '../../../resources/images/clock.svg'
import { Icon } from '../../../components/icon'
import { devices } from '../../../components/devices'
import { useStore } from '../../../hooks/useStores'

const progressBarConfig = {
  mobile: { from: 30, to: -60 },
  desktop: { from: 45, to: -90 },
}

const fadeIn = (key: keyof typeof progressBarConfig) => keyframes`
  from {
    opacity: 0;
    top: ${progressBarConfig[key].from}px;
  }
  to {
    opacity: 1;
    top: ${progressBarConfig[key].to}px;
  }
`

const fadeOut = (key: keyof typeof progressBarConfig) => keyframes`
  from {
    opacity: 1;
    top: ${progressBarConfig[key].to}px;
  }
  to {
    opacity: 0;
    top: ${progressBarConfig[key].from}px;
  }
`

const fadeInMs = 250

const Container = styled.div<{ isVisible: boolean }>`
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: center;
  animation: ${props => props.isVisible ? fadeIn('desktop') : fadeOut('desktop')} ${fadeInMs}ms ease forwards;

  @media ${devices.mobile} {
    animation: ${props => props.isVisible ? fadeIn('mobile') : fadeOut('mobile')} ${fadeInMs}ms ease forwards;
  }
`

const Notification = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  padding: 8px 24px;
  background: ${props => props.theme.tertiaryBgColor};
  backdrop-filter: blur(12px);
  border-radius: 24px;
`

const ClockIcon = styled(Icon)`
  margin-left: -8px;
`

const Text = styled.span`
  color: ${props => props.theme.primaryColor};
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-left: 4px;
  margin-left: 10px;

  @media ${devices.mobile} {
    font-size: 14px;
  }
`

export const TxsProgressBar = observer(() => {
  const { txRequests } = useStore('appTransactionsStore')
  const [tx] = txRequests

  return <Container isVisible={!!tx}>
    {tx &&
      <Notification>
        <ClockIcon backgroundImage={iconClock} size={20} />
        <Text>Transaction in progress: {tx.assetName} {OpTypeName[tx.type]}</Text>
      </Notification>
    }
  </Container>
})
