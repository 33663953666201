import { observer } from 'mobx-react-lite'
import wavesCardBg from '../../../../resources/images/waves-card-bg.png'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useStores } from '../../../../hooks/useStores'
import { getRouteIssue } from '../../../../consts/routes'
import { FlexWrapper } from '../../common/styled-components'
import { AddEast, AssetInfoItem, BottomItem, CollateralAssetsWrapper, Container, ContentWrapper, Description, PlusContainer, PlusImage } from './styled-components'
import { TokenBalance } from '../TokenBalance/TokenBalance'
import { getTrimmedAssetAmount, getTrimmedEastAmount } from '../../../../common/helpers'
import { Block } from '../../../../components/Block'

const InnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

export const IssueEastBlock = () => {
  const navigate = useNavigate()
  return <PlusContainer>
    <PlusImage onClick={() => navigate(getRouteIssue())} />
    <Block marginTop={24}>
      <AddEast onClick={() => navigate(getRouteIssue())}>Issue East</AddEast>
    </Block>
  </PlusContainer>
}

type FrontCardProps = {
  isShown: boolean,
  onClick: () => void,
  showFadeIn: boolean,
}

export const FrontCard = observer((props: FrontCardProps) => {
  const { wavesBalancesStore, wavesConfigStore, wavesVaultStore } = useStores()
  const vaultEastAmount = getTrimmedEastAmount(wavesVaultStore.vaultEastAmount)
  const eastBalance = getTrimmedEastAmount(wavesBalancesStore.eastBalance)
  // const orientBalance = getTrimmedAssetAmount(wavesBalancesStore.orientBalance)
  const collateralAssets = wavesVaultStore.vaultCollateralAssets
    .filter(asset => +asset.amount > 0)
    .map(asset => ({ ...asset, amount: getTrimmedAssetAmount(
      asset.amount,
      wavesConfigStore.getDecimalsByAssetId(asset.assetId)),
    }))

  return <Container {...props}>
    <ContentWrapper bgImage={wavesCardBg}>
      <InnerContainer>
        <AssetInfoItem>
          <TokenBalance
            integerPartSize={64}
            fracPartSize={32}
            letterSpacing={3}
          >
            {eastBalance}
          </TokenBalance>
          <Description>EAST available</Description>
        </AssetInfoItem>
        <FlexWrapper gap={24}>
          <AssetInfoItem>
            <TokenBalance integerPartSize={16}>{vaultEastAmount}</TokenBalance>
            <BottomItem>EAST issued with vault</BottomItem>
          </AssetInfoItem>
          {/* <AssetInfoItem>
                <TokenBalance integerPartSize={16}>{orientBalance}</TokenBalance>
                <BottomItem>Orient balance</BottomItem>
              </AssetInfoItem> */}
        </FlexWrapper>
        <CollateralAssetsWrapper>
          {collateralAssets.map(el => (
            <AssetInfoItem key={el.assetId}>
              <TokenBalance integerPartSize={14} id={'collateral-asset'}>{el.amount.toString()}</TokenBalance>
              <BottomItem
                fontSize={14}
                lineHeight={1.2}
              >
                {wavesConfigStore.getAssetNameById(el.assetId)} in vault
              </BottomItem>
            </AssetInfoItem>
          ))}
        </CollateralAssetsWrapper>

      </InnerContainer>
    </ContentWrapper>
  </Container>
})
