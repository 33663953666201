import React, { FC, memo } from 'react'
import styled, { css } from 'styled-components'
import ArrowLeft from '../resources/images/arrow-left.svg'
import { ButtonSpinner } from './Spinner'

type ButtonSize = 'default' | 'small'
type ButtonCustomType = 'default' | 'primary' | 'submit'
type ButtonDirection = 'left' | 'right'

export type ButtonProps = {
  disabled?: boolean,
  customType?: ButtonCustomType,
  size?: ButtonSize,
  children?: string | string[],
  style?: any,
  showSpinner?: boolean,
} & React.ButtonHTMLAttributes<HTMLButtonElement>

type NavigationButtonBrops = {
  direction?: ButtonDirection,
} & ButtonProps

type NavigationWithIconProps = {
  icon: string,
} & ButtonProps

const ButtonContainer = styled.button<{ size?: ButtonSize, customType?: ButtonCustomType, disabled?: boolean }>`
  font-family: Cairo, Helvetica,Arial,sans-serif;
  font-weight: 700;
  font-size: 16px;
  border: none;
  height: 55px;
  width: ${({ size }) => size === 'small' ? 'fit-content' : '100%'};
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F2F2F2;
  color: ${props => props.theme.tertiaryColor};
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  transition: background-position 0.5s;
  position: relative;
  display: flex;
  
  ${(props) => props.size === 'small' && `
    transition: transform 150ms;
    border-radius: 6px;
    font-weight: 700;
    height: 39px;
    min-width: 94px;
    background: ${props.theme.tertiaryColor};
    
    :hover {
      transform: scale(1.05);
    }
  `}

  ${({ customType }) => customType === 'primary' && `
    background-image: linear-gradient(to right, #545ff5 0%, #3b8ad9 51%, #4687dc 100%);
    background-size: 200% auto;
    color: white;
    
    :hover {
      background-position: right center;
    }

    :active {
      background-image: linear-gradient(to right, #3b43ac 0%, #2b6198 51%, #2e5f99 100%);
    }
  `}

  ${({ disabled, theme }) => disabled && css`
    background: ${theme.quaternaryBgColor};
    cursor: default;
    pointer-events: none;
    color: ${theme.tertiaryColor};
  `}
`

export const Button: FC<ButtonProps> = memo((props: ButtonProps) => {
  return <ButtonContainer {...props} disabled={props.disabled || props.showSpinner}>
    {props.showSpinner && <ButtonSpinner />}
    {props.children}
  </ButtonContainer>
})

const NaviButton = styled.button<{ direction?: ButtonDirection, disabled?: boolean }>`
  width: 64px;
  height: 55px;
  border: none;
  background: ${props => props.theme.tertiaryBgColor};
  box-sizing: border-box;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  transform: ${props => props.direction === 'right' && 'rotate(180deg)'};
`

const NaviContainer = styled(NaviButton) <{ showSpinner?: boolean }>`
  background-image: url(${ArrowLeft});
  position: relative;

  ${({ showSpinner }) => showSpinner && css`
    background-image: none;
    display: flex;
    justify-content: center;
    pointer-events: none;

    div {
      margin-right: 0;
    }
  `}

  ${({ disabled }) => disabled && css`
    opacity: 0.6;
  `}
`

const NaviWithIconContainer = styled(NaviButton) <{ icon: string }>`
  background-image: ${props => `url(${props.icon})`};
  background-size: 24px;
`

export const NavigationButton = memo((props: NavigationButtonBrops) => {
  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!props.disabled && props.onClick) {
      props.onClick(e)
    }
  }
  return <NaviContainer {...props} direction={props.direction || 'left'} onClick={onClick}>
    {props.showSpinner && <ButtonSpinner />}
  </NaviContainer>
})

export const NavigationWithIcon = memo((props: NavigationWithIconProps) => {
  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!props.disabled && props.onClick) {
      props.onClick(e)
    }
  }
  return <NaviWithIconContainer {...props} onClick={onClick} />
})

