import React, { FC, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useStores } from '../../../hooks/useStores'
import { Sidebar, SidebarProps } from '../../../components/Sidebar'
import { TransactionList } from '../common/TransactionList'
import { SidebarHeader } from '../common/SidebarHeader'

export const TransactionsSidebar: FC<Partial<SidebarProps>> = observer(() => {
  const { appTransactionsStore, wavesSignStore } = useStores()
  const { showTransactions, setShowTransactions, transactionsList } = appTransactionsStore
  const skipAnimation = useRef(!showTransactions)

  const onReload = async () => {
    appTransactionsStore.clearTransactionsList()
    await appTransactionsStore.updateTransactionsList()
  }

  const toggleShowStats = () => {
    setShowTransactions(!showTransactions)
    skipAnimation.current = false
  }

  if (!wavesSignStore.address) {
    return null
  }

  return <Sidebar
    title={<SidebarHeader position='right' title='Transactions' onReloadClick={onReload} />}
    isOpen={showTransactions}
    skipAnimation={skipAnimation.current}
    onToggleIsOpen={toggleShowStats}
    position='left'
    hideScrollBar={transactionsList && transactionsList?.length < 6}
  >
    <TransactionList />
  </Sidebar>
})