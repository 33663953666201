import { HTMLAttributes } from 'react'

export type IconProps = {
  size?: number,
  backgroundImage: string,
} & HTMLAttributes<HTMLDivElement>

export type CustomIconProps = Omit<IconProps, 'backgroundImage'>

export const defaultSize = 32
