import styled from 'styled-components'
import { Icon } from '../../../../components/icon'

export const DollarIcon = styled(Icon)`
  display: inline-flex;
  margin: 0;
  background-position: center;
  width: 16px;
  height: 18px;
  background-size: 18px;
  background-position: center;
`

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`
