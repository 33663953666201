import axios from 'axios'
import { ITransaction, TFullAssetData } from '../types/interfaces'
import { AssetProfits, EvaluateResponseError, EvaluateResult, IServiceConfigResponse, TAssetDetails, TBalanceResponse, TNodeInvokeScriptTransactionInfo, TNodeTransferTransactionInfo, WavesContractConfigItem } from './ApiInterfaces'

const WAVES_NODE_ADDRESS = '/wavesNodeAddress'
const API_ADDRESS = '/nextApiAddress'
const ORIENT_SERVICE = '/orient-service'

const getQueryString = (params: Record<string, string | number | undefined>) => {
  return '?' + Object.entries(params).map(([key, value]) => value !== undefined && `${key}=${value}`).filter(Boolean).join('&')
}

export class Api {
  // Waves Node requests
  getWavesContractData = async (contractId: string, matches: string = '', keys: string[] = []): Promise<WavesContractConfigItem[]> => {
    if (!contractId) {
      throw new Error(`ContractId param is not valid. contractId: ${contractId}`)
    }
    const keysQuery = keys.length ? '?key=' + keys.map(encodeURI).join('&key=') : ''
    const matchesQuery = matches ? '?matches=' + matches : ''
    const { data } = await axios.get(`${WAVES_NODE_ADDRESS}/addresses/data/${contractId}${matchesQuery}${keysQuery}`)
    return data
  }

  getWavesContractDataByKey = async (contractId: string, key: string): Promise<WavesContractConfigItem> => {
    if (!contractId || !key) {
      throw new Error(`One of required params is not valid. contractId: ${contractId}, key: ${key}`)
    }
    const { data } = await axios.get(`${WAVES_NODE_ADDRESS}/addresses/data/${contractId}/${encodeURI(key)}`)
    return data
  }

  getWavesContractDataByKeys = async (contractId: string, keys: string[] = []): Promise<WavesContractConfigItem[]> => {
    if (!contractId || !keys.length) {
      throw new Error(`One of required params is not valid. contractId: ${contractId}, keys: ${keys}`)
    }
    const { data } = await axios.post(`${WAVES_NODE_ADDRESS}/addresses/data/${contractId}`, { keys })
    return data
  }

  getWavesAddressBalance = async (address: string) => {
    if (!address) {
      throw new Error(`Address param is not valid. address: ${address}`)
    }
    const { data } = await axios.get(`${WAVES_NODE_ADDRESS}/addresses/balance/${address}`)
    return data.balance
  }

  getWavesAddressAssetsBalances = async (address: string, assetsIds: string[]): Promise<TBalanceResponse[]> => {
    if (!address || !assetsIds) {
      throw new Error(`One of required params is not valid. Address: ${address}, assetsIds: ${assetsIds}`)
    }
    const { data } = await axios.post(`${WAVES_NODE_ADDRESS}/assets/balance/${address}`, {
      ids: assetsIds,
    })
    return data.balances
  }

  getTransactionById = async (txId: string)
    : Promise<TNodeInvokeScriptTransactionInfo | TNodeTransferTransactionInfo> => {
    if (!txId) {
      throw new Error(`TxId param is not valid. txId: ${txId}`)
    }
    const { data } = await axios.get(`${WAVES_NODE_ADDRESS}/transactions/info/${txId}`)
    return data
  }

  getTransactionsList = async (
    sender: string,
    after?: string,
    limit = 10,
  ): Promise<Array<TNodeInvokeScriptTransactionInfo | TNodeTransferTransactionInfo>> => {
    if (!sender) {
      throw new Error(`One of required params is not valid. sender: ${sender}`)
    }
    const query = getQueryString({
      after,
    })
    const { data } = await axios.get(`${WAVES_NODE_ADDRESS}/transactions/address/${sender}/limit/${limit}${query}`)
    return data.flat()
  }

  evaluate = async (contractAddress: string, expression: { expr: string }): Promise<EvaluateResult> => {
    if (!contractAddress || !expression.expr) {
      throw new Error(`One of required params is not valid. contractAddress: ${contractAddress}, expression: ${expression}`)
    }
    const url = `${WAVES_NODE_ADDRESS}/utils/script/evaluate/${contractAddress}`
    const { data } = await axios.post(url, expression)
    if (data.error) {
      const errorData = {
        ...data,
        message: data.message.slice(0, data.message.indexOf('log')).trim().replace(/.$/, ')'),
      }
      throw new EvaluateResponseError(errorData)
    }
    return data
  }

  // API requests
  getServiceConfig = async (): Promise<IServiceConfigResponse> => {
    const { data } = await axios.get(`${API_ADDRESS}/config`)
    return data as IServiceConfigResponse
  }

  getOrientClaimableAmount = async (address: string): Promise<string> => {
    if (!address) {
      throw new Error(`Address param is not valid. address: ${address}`)
    }
    const { data } = await axios.get(`${ORIENT_SERVICE}/claimable-amount?address=${address}`)
    return data.result
  }

  getAssets = async (): Promise<TFullAssetData[]> => {
    const { data } = await axios.get(`${API_ADDRESS}/assets`)
    return data as TFullAssetData[]
  }

  getTransactionsHistory = async (address: string, limit = 1000, offset = 0): Promise<ITransaction[]> => {
    if (!address) {
      throw new Error(`Address param is not valid. address: ${address}`)
    }
    const { data } = await axios.get(`${API_ADDRESS}/transactions?address=${address}&limit=${limit}&offset=${offset}`)
    return data as ITransaction[]
  }

  getStakingAPY = async (days = 365): Promise<{apy: string}> => {
    const { data } = await axios.get(`${API_ADDRESS}/staking/apy?days=${days}`)
    return data as { apy: string }
  }

  getProfits = async (address: string, days = 365): Promise<Record<string, AssetProfits>> => {
    if (!address) {
      throw new Error(`Address param is not valid. address: ${address}`)
    }
    const { data } = await axios.get(`${API_ADDRESS}/staking/profits?address=${address}&days=${days}`)
    return data as Record<string, AssetProfits>
  }

  sendFeedback = async (contactEmail: string, text: string) => {
    if (!contactEmail || !text) {
      throw new Error(`One of required params is not valid. contactEmail: ${contactEmail}, text: ${text}`)
    }
    const { data } = await axios.post(`${API_ADDRESS}/user/feedback`, { contactEmail, text })
    return data
  }

  // EAST Stats
  getAssetDetailsByAssetId = async (assetId: string): Promise<TAssetDetails> => {
    if (!assetId) {
      throw new Error(`AssetId param is not valid. assetId: ${assetId}`)
    }
    const { data } = await axios.get(`${WAVES_NODE_ADDRESS}/assets/details/${assetId}`)
    return data
  }

  getAssetTotalSupplyByAssetId = async (assetId: string): Promise<string> => {
    if (!assetId) {
      throw new Error(`AssetId param is not valid. assetId: ${assetId}`)
    }
    const { data } = await axios.get(`${API_ADDRESS}/dashboard/total-supply/${assetId}`)
    return data.result === 'NaN' ? '0' : data.result
  }

  getEastStaked = async () => {
    const { data } = await axios.get(`${API_ADDRESS}/dashboard/east-staked`)
    return data.result
  }

  getEastAPR = async () => {
    const { data } = await axios.get(`${API_ADDRESS}/dashboard/apr`)
    return data.result
  }
}
