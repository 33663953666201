import React from 'react'
import styled from 'styled-components'
import { ReloadIcon } from '../../../components/icon'

type SidebarHeaderProps = {
  title: string,
  position: 'left' | 'right',
  offset?: number,
  onReloadClick: () => void,
}

const Header = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const SidebarHeader = (props: SidebarHeaderProps) => {
  return <Header>
    {props.title}
    <ReloadIcon onClick={props.onReloadClick} size={26} {...props} />
  </Header>
}