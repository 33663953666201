import React from 'react'
import { toast } from 'react-toastify'
import { ErrorNotification } from '../components/Notification'
import { DECIMAL_PLACES, WAVES_KEYS_SEPARATOR } from '../consts/constants'
import { getAssetAmountFloat, getEastAmountFloat, roundNumber } from './utils'
import { AnyBNType } from './math'
import BigNumber from 'bignumber.js'

export type IThrowNotification = {
  title: string,
  message: string,
}

export const showErrorToast = ({ title, message }: IThrowNotification) => {
  toast.dismiss()
  toast(<ErrorNotification
    title={title}
    message={message}
  />, {
    hideProgressBar: true,
    autoClose: 10000,
  })
}

export const getContractKey = (...keys: string[]) => [...keys].join(WAVES_KEYS_SEPARATOR)

export function getProperty<T, K extends keyof T>(obj: T, key: K): T[K] {
  return obj[key]
}

export const getTrimmedEastAmount = (amount: AnyBNType) => {
  const eastAmountFloat = getEastAmountFloat(amount)
  return new BigNumber(eastAmountFloat).isGreaterThan(0.001) ?
    roundNumber(eastAmountFloat, 3, BigNumber.ROUND_DOWN) :
    eastAmountFloat
}

export const getTrimmedAssetAmount = (amount: AnyBNType, decimals = DECIMAL_PLACES) => {
  const assetAmountFloat = getAssetAmountFloat(amount, decimals)
  return new BigNumber(assetAmountFloat).isGreaterThan(0.001) ?
    roundNumber(assetAmountFloat, 3, BigNumber.ROUND_DOWN) :
    assetAmountFloat
}

export const truncateString = (str: string, length: number, separator = '...') => {
  if (!str) {
    return ''
  }

  if (str.length <= length) {
    return str
  }

  const pad = Math.round(length - separator.length) / 2
  const start = str.slice(0, pad)
  const end = str.slice(str.length - pad)

  return [start, separator, end].join('')
}

export const getUppercased = (str: string) => {
  if (!str) {
    return ''
  }

  return str.charAt(0).toUpperCase() + str.slice(1)
}