import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../hooks/useStores'
import { EastStatsSidebar, TransactionsSidebar } from '../Sidebars'

export const Sidebars = observer(() => {
  const { isMobile } = useStore('appConfigStore')

  if (isMobile) {
    return null
  }

  return <>
    <EastStatsSidebar />
    <TransactionsSidebar />
  </>
})
