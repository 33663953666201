import styled, { keyframes } from 'styled-components'
import { Button } from '../../../components/Button'
import { devices } from '../../../components/devices'

export const AccountWrapper = styled.div`
  height: 100vh;
`

export const AccountInner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;

  flex-direction: column;
  top: 50%;
  transform: translateY(-50%);

  @media (orientation: landscape) {
    transform: unset;
    top: 20%;
  }
`

export const SelectNetworkButton = styled(Button)`
  width: 170px;
  margin-top: 50px;

  @media ${devices.mobile} and (orientation: landscape) {
    margin-top: 10px;
  }
`

const fadeIn = keyframes`
  from {
    opacity: 0;
    top: 0;
  }
  to {
    opacity: 1;
    top: -13%;
  }
`

export const OrientNotificationWrapper = styled.div<{isVisible: boolean}>`
  background-color: ${props => props.theme.quaternaryColor};
  padding: 4px 12px;
  text-align: center;
  border-radius: 4px;
  text-transform: uppercase;
  color: ${props => props.theme.primaryColor};
  font-size: 11px;
  position: absolute;
  top: -5%;
  opacity: 0;
  animation: ${props => props.isVisible && fadeIn} 750ms ease forwards;
`
