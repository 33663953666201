import React from 'react'
import { Navigate } from 'react-router-dom'
import { getRouteNotFound } from '../consts/routes'
import { useStore } from '../hooks/useStores'
import { observer } from 'mobx-react-lite'

type ProtectedRouteProps = {
  children: JSX.Element,
}

export const MobileOnly = observer(({ children }: ProtectedRouteProps) => {
  const { isMobile } = useStore('appConfigStore')

  if (isMobile) {
    return children
  }

  return <Navigate to={getRouteNotFound()} />
})