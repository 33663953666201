import styled from 'styled-components'
import eastRoundLogo from '../../resources/images/east-round-logo.svg'
import eastLogo from '../../resources/images/east-logo.svg'
import { BlackoutWrapper } from '../account/common/styled-components'
import arrowLeft from '../../resources/images/arrow-left-purple.svg'
import tick from '../../resources/images/tick.svg'

export const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #043569;

  h1 {
    font-size: 48px;
  }

  h2 {
    margin: 0;
  }
`

export const Inner = styled.div`
  position: absolute;
  top: 15%;
`

export const Logo = styled.div`
  filter: invert(100%);

  position: absolute;
  top: 20px;
  left: 20px;
  width: 174px;
  height: 55px;
  background-image: url(${eastLogo});
`

export const DescriptionItem = styled.div<{ num: string }>`
  display: flex;
  align-items: center;
  font-size: 15px;
  margin: 10px 0;

  :before {
    content: ${props => `"${props.num}"`};
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    width: 32px;
    height: 32px;
    background-color: rgba(238, 238, 238, 1);
    border-radius: 50%;
    margin-right: 10px;
  }
`

export const ButtonBlock = styled.div`
  display: flex;
  margin-top: 40px;

  button {
    width: fit-content;
    padding: 0 30px;
  }
`

export const EastVersionBlock = styled.div`
  display: inline-block;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;

  ::before {
    content: "";
    background-image: url(${eastRoundLogo});
    height: 24px;
    width: 24px;
    object-fit: contain;
    margin-right: 10px;
  }
  
`

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const BlackoutWrap = styled(BlackoutWrapper)`
  border-radius: 16px;
  padding: 24px;
  width: fit-content;
  background-color: rgba(4, 53, 105, 0.07);
  color: #043569;
`

export const BlackoutWrapSuccess = styled(BlackoutWrap)`
  width: 280px;
  border: 2px solid rgba(0, 128, 94, 1);
`

export const BlackoutWrapSuccessInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Arrow = styled.div`
  width: 42px;
  height: 42px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${arrowLeft});
  transform: rotate(180deg);
  margin: 0 50px;
`

export const Tick = styled.div`
  width: 48px;
  height: 48px;
  background-image: url(${tick});
`