import { makeAutoObservable, onBecomeObserved, onBecomeUnobserved, runInAction } from 'mobx'
import { getAssetAmountFloat } from '../../common/utils'
import WavesConfigStore from './WavesConfigStore'
import { Api } from '../../api'
import { BALANCES_POLLING_INTERVAL_MS } from '../../consts/constants'

type Balances = Record<string, number>

export default class WavesBalancesStore {
  private api: Api
  private wavesConfigStore: WavesConfigStore
  private pollingWavesBalanceId: NodeJS.Timeout | null = null
  private pollingAssetsBalancesId: NodeJS.Timeout | null = null

  private address = ''
  wavesBalance = '0'
  assetsBalances: Balances = {}

  constructor(
    api: Api,
    wavesConfigStore: WavesConfigStore,
  ) {
    makeAutoObservable(this, {
      startPollingAssetsBalances: false,
      startPollingWavesBalance: false,
      stopPollingByIntervalId: false,
    })
    this.api = api
    this.wavesConfigStore = wavesConfigStore

    onBecomeObserved(this, 'assetsBalances', this.startPollingAssetsBalances)
    onBecomeUnobserved(this, 'assetsBalances', () => this.stopPollingByIntervalId(this.pollingAssetsBalancesId))

    onBecomeObserved(this, 'eastBalance', this.startPollingWavesBalance)
    onBecomeUnobserved(this, 'eastBalance', () => this.stopPollingByIntervalId(this.pollingWavesBalanceId))
  }

  setSelectedAddress(address: string) {
    this.address = address
  }

  reset() {
    this.assetsBalances = {}
    this.stopPollingByIntervalId(this.pollingAssetsBalancesId)
    this.stopPollingByIntervalId(this.pollingWavesBalanceId)
  }

  getAssetBalanceById(assetId: string): string {
    if (assetId === this.wavesConfigStore.feeAssetId) {
      return this.wavesBalance
    }
    const assetBalance = this.assetsBalances?.[assetId]?.toString() || '0'
    return assetBalance
  }

  get eastBalance() {
    return this.getAssetBalanceById(this.wavesConfigStore.eastAssetId)
  }

  get orientBalance() {
    return this.getAssetBalanceById(this.wavesConfigStore.orientAssetId)
  }

  get stakedEastBalance() {
    return this.getAssetBalanceById(this.wavesConfigStore.stEastAssetId)
  }

  get feeAssetBalanceFloat() {
    return getAssetAmountFloat(this.wavesBalance)
  }

  async setWavesBalance() {
    try {
      const wavesBalance = await this.api.getWavesAddressBalance(this.address)
      runInAction(() => {
        this.wavesBalance = wavesBalance
      })
    } catch (e: any) {
      console.error('Error on getting east balance', e.message)
    }
  }

  startPollingWavesBalance = async () => {
    this.stopPollingByIntervalId(this.pollingWavesBalanceId)

    await this.setWavesBalance()

    runInAction(() => {
      this.pollingWavesBalanceId = setInterval(async () => {
        await this.setWavesBalance()
      }, BALANCES_POLLING_INTERVAL_MS)
    })
  }

  stopPollingByIntervalId(intervalId: NodeJS.Timeout | null) {
    if (intervalId) {
      clearInterval(intervalId)
    }
  }

  async setAssetsBalances() {
    try {
      const assetsIds = Object.keys(this.wavesConfigStore.assets)
      assetsIds.push(
        this.wavesConfigStore.eastAssetId,
        this.wavesConfigStore.oldEastAssetId,
        this.wavesConfigStore.stEastAssetId,
        this.wavesConfigStore.oldEastAssetId,
      )
      const balances = await this.api.getWavesAddressAssetsBalances(this.address, assetsIds)
      const normalizedBalances = balances.reduce((acc, cur) => {
        const { assetId, balance } = cur
        return {
          ...acc,
          [assetId]: balance,
        }
      }, {} as Balances)
      runInAction(() => {
        this.assetsBalances = normalizedBalances
      })
    } catch (e: any) {
      console.error('Error on getting east balance', e.message)
    }
  }

  startPollingAssetsBalances = async () => {
    this.stopPollingByIntervalId(this.pollingAssetsBalancesId)

    await this.setAssetsBalances()

    runInAction(() => {
      this.pollingAssetsBalancesId = setInterval(async () => {
        await this.setAssetsBalances()
      }, BALANCES_POLLING_INTERVAL_MS)
    })
  }
}