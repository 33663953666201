
import React, { useRef, useState } from 'react'
import { DetailedCard } from './DetailedCard'
import { FrontCard } from './FrontCard'
import styled from 'styled-components'
import { useOutsideClick } from '../../../hooks/useOutsideClick'
import { useStores } from '../../../hooks/useStores'
import { observer } from 'mobx-react-lite'
import { EmptyCard } from './EmptyCard'

const Wrapper = styled.div`
  position: relative;
`

export const Card = observer(() => {
  const { wavesVaultStore, wavesBalancesStore } = useStores()
  const showEmptyCard = +wavesBalancesStore.eastBalance === 0 &&
    !wavesVaultStore.nonZeroCollateralAssets.length && +wavesBalancesStore.orientBalance === 0

  if (showEmptyCard) {
    return <EmptyCard />
  }

  return <Cards />
})

const Cards = () => {
  const [isFrontShown, setFrontShown] = useState(true)
  const showFadeInAnimation = useRef(true)
  const wrapperRef = useRef(null)
  useOutsideClick(wrapperRef, () => !isFrontShown && toggleCard())

  const toggleCard = () => {
    showFadeInAnimation.current = false
    setFrontShown(prev => !prev)
  }

  return <Wrapper ref={wrapperRef}>
    <FrontCard
      isShown={isFrontShown}
      showFadeIn={showFadeInAnimation.current}
      onClick={toggleCard}
    />
    <DetailedCard
      isShown={!isFrontShown}
      showFadeIn={showFadeInAnimation.current}
      onClick={toggleCard}
    />
  </Wrapper>
}
