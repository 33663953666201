import React, { ErrorInfo, ReactNode, Suspense } from 'react'
import { PageError } from './PageError'
import * as Sentry from '@sentry/react'

type ErrorBoundaryProps = {
    children: ReactNode,
}

type ErrorBoundaryState = {
    hasError: boolean,
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(_error: Error) {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.captureException(error)
    console.error({ errorInfo })
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props

    if (hasError) {
      return (
        <Suspense fallback="">
          <PageError />
        </Suspense>
      )
    }

    return children
  }
}
