export const MaxTokenAmount = 1_000_000_000

export const WAVES_ASSET_ID = 'WAVES'
export const WAVES_KEYS_SEPARATOR = '__'

export const PRECISION = 1e12
export const ORACLE_DECIMALS = 1e6
export const DECIMAL_PLACES = 8
export const EAST_DECIMAL_PLACES = 6

export const WAVES_MIN_AMOUNT = 1000

export const RATES_POLLING_INTERVAL_MS = 5000
export const VAULT_DATA_POLLING_INTERVAL_MS = 5000
export const STAKING_DATA_POLLING_INTERVAL_MS = 5000
export const BALANCES_POLLING_INTERVAL_MS = 5000
export const TRANSACTIONS_POLLING_INTERVAL_MS = 5000
export const ORIENT_POLLING_INTERVAL_MS = 300000 // 5 min

export const INFINITE_BR = 1e4
