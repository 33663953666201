import { autorun, makeAutoObservable, runInAction } from 'mobx'
import AppTransactionsStore from '../AppTransactionsStore'
import { AssetName, EastOpType } from '../../types/interfaces'
import { InvokeArgs, Signer, TransferArgs } from '@waves/signer'
import { ProviderKeeper } from '@waves/provider-keeper'
import { ProviderKeeperMobile } from '@keeper-wallet/provider-keeper-mobile'
import { ProviderCloud } from '@waves.exchange/provider-cloud'
import { ProviderWeb } from '@waves.exchange/provider-web'
import { ProviderLedger } from '@waves/provider-ledger'
import WavesConfigStore from './WavesConfigStore'
import { ProviderMetamask } from '@waves/provider-metamask'
import { Api } from '../../api'

const PROVIDER_KEY = 'selectedProvider'

type LSProviderKeyData = {
  type: Providers,
}

type SignAndPublishTxOptions = {
  watchTx?: boolean,
  assetName?: AssetName,
}

export enum Providers {
  PROVIDER_KEEPER = 'PROVIDER_KEEPER',
  PROVIDER_KEEPER_MOBILE = 'PROVIDER_KEEPER_MOBILE',
  PROVIDER_CLOUD = 'PROVIDER_CLOUD',
  PROVIDER_WEB = 'PROVIDER_WEB',
  PROVIDER_LEDGER = 'PROVIDER_LEDGER',
  PROVIDER_METAMASK = 'PROVIDER_METAMASK',
}

export const providersConfig: {[key in Providers]?: {connectOnReload: boolean}} = {
  [Providers.PROVIDER_KEEPER]: { connectOnReload: true },
  [Providers.PROVIDER_METAMASK]: { connectOnReload: true },
}

export default class WavesSignStore {
  private api: Api
  private appTransactionsStore: AppTransactionsStore
  private wavesConfigStore: WavesConfigStore
  private _signer: Signer | undefined

  selectedProviderKey: Providers | undefined
  address = ''

  constructor(api: Api, wavesConfigStore: WavesConfigStore, appTransactionsStore: AppTransactionsStore) {
    makeAutoObservable(this)
    this.api = api
    this.wavesConfigStore = wavesConfigStore
    this.appTransactionsStore = appTransactionsStore

    autorun(() => {
      if (this.wavesConfigStore.isConfigLoaded) {
        this.initSigner()
      }
    })
  }

  setSelectedAddress(address: string) {
    this.address = address
  }

  private initSigner() {
    this._signer = new Signer({
      NODE_URL: this.wavesConfigStore.nodeAddress,
    })
  }

  private get signer() {
    if (this._signer) {
      return this._signer
    }
    throw new Error('Signer is not inited')
  }

  reset() {
    this.selectedProviderKey = undefined
    this.address = ''
  }

  async setUserDataFromLs() {
    try {
      const lsData = this.getSelectedProviderFromLS()
      if (lsData) {
        this.selectedProviderKey = lsData.type
        await this.login(lsData.type)
      }
    } catch (e: any) {
      console.error(e.message)
    }
  }

  async login(selectedProvider: Providers) {
    try {
      this.setProvider(selectedProvider)
      const user = await this.signer.login()
      if (providersConfig[selectedProvider]?.connectOnReload) {
        this.setSelectedProviderToLS({ type: selectedProvider })
      } else {
        this.removeSelectedProviderFromLS()
      }
      if (user) {
        runInAction(() => {
          this.address = user.address
          this.selectedProviderKey = selectedProvider
        })
        return user
      }
    } catch (e: any) {
      throw new Error(e.message)
    }
  }

  async logout() {
    try {
      await this.signer.logout()
      this.removeSelectedProviderFromLS()
      runInAction(() => {
        this.address = ''
      })
    } catch (e: any) {
      console.error(e.message)
    }
  }

  setSelectedProviderToLS(data: LSProviderKeyData) {
    localStorage.setItem(PROVIDER_KEY, JSON.stringify(data))
  }

  getSelectedProviderFromLS() {
    try {
      const data = localStorage.getItem(PROVIDER_KEY)
      if (data) {
        const parsedData: LSProviderKeyData = JSON.parse(data)
        return parsedData
      }
      return null
    } catch (e: any) {
      console.error(e.message)
      return null
    }
  }

  removeSelectedProviderFromLS() {
    localStorage.removeItem(PROVIDER_KEY)
  }

  private setProvider(provider: Providers) {
    try {
      switch (provider) {
        case Providers.PROVIDER_KEEPER:
          this.signer.setProvider(new ProviderKeeper())
          break

        case Providers.PROVIDER_KEEPER_MOBILE:
          this.signer.setProvider(new ProviderKeeperMobile())
          break

        case Providers.PROVIDER_CLOUD:
          this.signer.setProvider(new ProviderCloud())
          break

        case Providers.PROVIDER_WEB:
          this.signer.setProvider(new ProviderWeb())
          break

        case Providers.PROVIDER_LEDGER:
          this.signer.setProvider(new ProviderLedger({
            wavesLedgerConfig: { networkCode: this.wavesConfigStore.chainId.charCodeAt(0) },
          }))
          break

        case Providers.PROVIDER_METAMASK:
          this.signer.setProvider(new ProviderMetamask())
          break

        default:
          this.signer.setProvider(new ProviderKeeper())
      }
    } catch (e: any) {
      throw new Error(e)
    }
  }

  startWatchDockerCallStatus(broadcastTx: any, type?: EastOpType, assetName?: AssetName) {
    return this.appTransactionsStore.watchTxStatus({
      ...broadcastTx,
      type,
      address: broadcastTx.sender ,
      assetName,
    })
  }

  async sendTransferTx(tx: TransferArgs) {
    try {
      const [result] = await this.signer.transfer(tx).broadcast()
      if (result) {
        this.startWatchDockerCallStatus(result, EastOpType.transfer, 'EAST')
      }
      return result
    } catch (e: any) {
      throw new Error(`Cannot start watch tx status: ${e.message}. Call tx body: ${tx}`)
    }
  }

  async sendInvokeTx(tx: InvokeArgs, options: SignAndPublishTxOptions = {}) {
    const { watchTx = true, assetName = 'EAST' } = options
    try {
      const [result] = await this.signer.invoke(tx).broadcast()
      if (result && watchTx) {
        this.startWatchDockerCallStatus(result, tx.call?.function as EastOpType, assetName)
      }
      return result
    } catch (e: any) {
      throw new Error(`Cannot start watch tx status: ${e.message}. Call tx body: ${tx}`)
    }
  }
}