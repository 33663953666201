import { getRouteClosePosition, getRouteConnectWallet, getRouteEastStats, getRouteGetOvercollateral, getRouteIssue, getRouteSettings, getRouteStaking, getRouteSupply, getRouteTransactions, getRouteTransfer } from '../../consts/routes'

export const EastOpTypeModalTitle = {
  [getRouteConnectWallet()]: 'Сonnect your wallet',
  [getRouteIssue()]: 'Issue East',
  [getRouteClosePosition()]: 'Close Vault',
  [getRouteStaking()]: 'Stake East and Orient',
  [getRouteSupply()]: 'Supply Vault',
  [getRouteGetOvercollateral()]: 'Claim Overcollateral',
  [getRouteTransfer()]: 'Transfer East',
  [getRouteEastStats()]: 'East Stats',
  [getRouteSettings()]: 'Settings',
  [getRouteTransactions()]: 'Transactions',
}
