import styled from 'styled-components'

export const Link = styled.a`
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  cursor: pointer;
  color: ${props => props.theme.quaternaryColor};
`

