import styled from 'styled-components'
import crossImg from '../../resources/images/cross.svg'

export const CrossIcon = styled.div<{ color?: string, size?: string }>`
  width: ${props => props.size || '48px'};
  height: ${props => props.size || '48px'};
  mask-image: url(${crossImg});
  mask-size: contain;
  background-color: ${props => props.color ? props.color : props.theme.tertiaryColor};
  background-repeat: no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
`