import { useStores } from '../hooks/useStores'

export const useStoreManager = () => {
  const { wavesVaultStore, wavesStakingStore, wavesSignStore, wavesBalancesStore, appTransactionsStore } = useStores()

  const onConnectProvider = async (address: string) => {
    appTransactionsStore.clearTransactionsList()
    wavesSignStore.setSelectedAddress(address)
    wavesBalancesStore.setSelectedAddress(address)
    wavesVaultStore.setSelectedAddress(address)
    wavesStakingStore.setSelectedAddress(address)
    appTransactionsStore.startWatchTxsForNewAddress(address)
    await Promise.allSettled([
      wavesVaultStore.setVaultData(),
      wavesBalancesStore.setWavesBalance(),
      wavesBalancesStore.setAssetsBalances(),
      wavesBalancesStore.setWavesBalance(),
      appTransactionsStore.updateTransactionsList(),
    ])
  }

  const onDisconnectProvider = () => {
    wavesSignStore.reset()
    wavesVaultStore.setSelectedAddress('')
    wavesVaultStore.reset()
    wavesStakingStore.setSelectedAddress('')
    wavesStakingStore.reset()
    wavesBalancesStore.setSelectedAddress('')
    wavesBalancesStore.reset()
  }

  return {
    onConnectProvider,
    onDisconnectProvider,
  }
}