
import React from 'react'
import { ToastContainer as ReactToastifyToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const ToastContainer = () => {
  return <ReactToastifyToastContainer
    closeOnClick
    toastClassName={'toast'}
    theme='dark'
  />
}