import React from 'react'
import { Outlet } from 'react-router-dom'
import { RatesBlock } from './Account/RatesBlock'
import { BackgroundWrapper } from './common/styled-components'

export const AccountLayout = () => {
  return <>
    <RatesBlock />
    <BackgroundWrapper />
    <Outlet />
  </>
}