import { makeAutoObservable, runInAction } from 'mobx'
import { TAssetData, EastOpType, TAssets, TFullAssetData } from '../../types/interfaces'
import { Api } from '../../api'
import { DECIMAL_PLACES, EAST_DECIMAL_PLACES, WAVES_ASSET_ID } from '../../consts/constants'
import { getAssetAmountFloat } from '../../common/utils'

const EXCHANGE_ADDRESS_KEY = '%s__exchangeAddress'
const OLD_EAST_ASSET_KEY = '%s__oldEastAsset'
const MIN_AMOUNT_DELTA_KEY = '%s__minAmountDelta'
const EAST_ASSET_ID_KEY = '%s__eastAsset'
const STEAST_ASSET_ID_KEY = '%s__stEastAsset'
const FRONTEND_CONTRACT_ID = '%s__frontendAddress'
const EAST_STAKING_CONTRACT_ID = '%s__eastStakingAddress'
const ORIENT_ASSET_ID = '%s__orientAsset'
const ORIENT_STAKING_CONTRACT_ID = '%s__orientStakingAddress'
const ORIENT_WESTING_CONTRACT_ID = '%s__orientWestingAddress'

export default class WavesConfigStore {
  private api: Api
  assets: TAssets = {}
  chainId = ''
  nodeAddress = ''
  isConfigLoaded = false

  eastAssetId = ''
  stEastAssetId = ''
  oldEastAssetId = ''
  orientAssetId = ''

  coordinatorContractId = ''
  eastContractId = ''
  eastStakingContractId = ''
  orientWestingContractId = ''
  orientStakingContractId = ''
  exchangeContractId = ''
  minAmountDelta = '0'

  constructor(api: Api) {
    this.api = api
    makeAutoObservable(this)

    this.init()
  }

  get allContractIds() {
    return [
      this.eastContractId,
      this.eastStakingContractId,
      this.orientWestingContractId,
      this.orientStakingContractId,
      this.exchangeContractId,
    ]
  }

  async init() {
    try {
      await Promise.all([this.loadServiceConfig(), this.setAvailableCollateralAssets()])
      await this.loadCoordinatorConfig()
      await this.loadOldEastAssetId()
      runInAction(() => {
        this.isConfigLoaded = true
      })
    } catch (e: any) {
      console.error(e.message)
    }
  }

  getAssetNameById(assetId: string) {
    if (assetId === this.eastAssetId) {
      return 'EAST'
    }
    if (assetId === this.stEastAssetId) {
      return 'STEAST'
    }
    return this.assets[assetId]?.assetName || ''
  }

  getAssetsValuesMapByKey(key: keyof TAssetData): Record<string, string> {
    return Object.entries(this.assets).reduce((acc, [assetId, assetData]) => {
      return {
        ...acc,
        [assetId]: assetData[key],
      }
    }, {})
  }

  getAssetsDataMapByKey(key: keyof TAssetData) {
    return Object.entries(this.assets).reduce((acc, [assetId, assetData]) => {
      return {
        ...acc,
        [assetData[key]]: {
          ...assetData,
          assetId,
        },
      }
    }, {} as Record<string, TFullAssetData>)
  }

  get feeAssetId() {
    return WAVES_ASSET_ID
  }

  get assetsBr() {
    return this.getAssetsValuesMapByKey('assetBr')
  }

  get assetsLr() {
    return this.getAssetsValuesMapByKey('assetLr')
  }

  private async loadServiceConfig() {
    try {
      const config = await this.api.getServiceConfig()
      runInAction(() => {
        this.coordinatorContractId = config.coordinatorContractId
        this.chainId = config.chainId
        this.nodeAddress = config.nodeAddress
      })
      console.log('Service config loaded')
    } catch (e: any) {
      throw new Error('Error on load WE service config')
    }
  }

  private async loadCoordinatorConfig() {
    try {
      const data = await this.api.getWavesContractData(this.coordinatorContractId)
      const normalizedData = data.reduce((acc, cur) => {
        return {
          ...acc,
          [cur.key]: { value: cur.value },
        }
      }, {} as Record<string, {value: string}>)
      runInAction(() => {
        this.exchangeContractId = normalizedData[EXCHANGE_ADDRESS_KEY]?.value || ''
        this.minAmountDelta = normalizedData[MIN_AMOUNT_DELTA_KEY]?.value || ''
        this.eastAssetId = normalizedData[EAST_ASSET_ID_KEY]?.value || ''
        this.eastContractId = normalizedData[FRONTEND_CONTRACT_ID]?.value || ''
        this.stEastAssetId = normalizedData[STEAST_ASSET_ID_KEY]?.value || ''
        this.eastStakingContractId = normalizedData[EAST_STAKING_CONTRACT_ID]?.value || ''
        this.orientAssetId = normalizedData[ORIENT_ASSET_ID]?.value || ''
        this.orientStakingContractId = normalizedData[ORIENT_STAKING_CONTRACT_ID]?.value || ''
        this.orientWestingContractId = normalizedData[ORIENT_WESTING_CONTRACT_ID]?.value || ''
      })
    } catch (e: any) {
      throw new Error(`Error on load coordinator config(contractId: ${this.coordinatorContractId})`)
    }
  }

  private async loadOldEastAssetId() {
    try {
      const data = await this.api.getWavesContractDataByKey(this.exchangeContractId, OLD_EAST_ASSET_KEY)
      if (data) {
        runInAction(() => {
          this.oldEastAssetId = data.value
        })
      }
    } catch (e: any) {
      throw new Error(`Error on load ols east asset id (contractId: ${this.exchangeContractId})`)
    }
  }

  private async setAvailableCollateralAssets() {
    try {
      const assets = await this.api.getAssets()
      const mapAssets = assets.reduce((acc, { assetId, ...assetData }) => ({
        ...acc,
        [assetId]: assetData,
      }), {} as TAssets)
      runInAction(() => {
        this.assets = mapAssets
      })
    } catch (e: any) {
      console.error('Error on getting assets', e.message)
    }
  }

  get invokeScriptFee() {
    return 500000
  }

  get transferFee() {
    return 100000
  }

  getMinAmountByOpType(_opType: EastOpType): string {
    return getAssetAmountFloat(this.minAmountDelta)
  }

  getDecimalsByAssetId(assetId: string): number {
    const mapAssetToDecimals = Object.entries(this.assets).reduce((acc, [assetId, assetData]) => {
      return {
        ...acc,
        [assetId]: assetData.decimals,
      }
    }, {} as Record<string, number>)
    const decimalsMap: Record<string, number> = {
      [this.eastAssetId]: EAST_DECIMAL_PLACES,
      [this.stEastAssetId]: DECIMAL_PLACES,
      [this.orientAssetId]: DECIMAL_PLACES,
      [this.oldEastAssetId]: DECIMAL_PLACES,
      ...mapAssetToDecimals,
    }

    return decimalsMap[assetId] || DECIMAL_PLACES
  }

  getFeeByOpType(opType: EastOpType): string {
    const callFee = this.invokeScriptFee
    const transferFee = this.transferFee

    const feeByOpTypeMapper: Record<EastOpType, number> = {
      [EastOpType.mint]: callFee,
      [EastOpType.supply]: callFee,
      [EastOpType.reissue]: callFee,
      [EastOpType.transfer]: transferFee,
      [EastOpType.close]: callFee,
      [EastOpType.stake]: callFee,
      [EastOpType.unstake]: callFee,
      [EastOpType.exchange]: callFee,
      [EastOpType.liquidate]: callFee,
    }

    const resultFee = feeByOpTypeMapper[opType]
    return getAssetAmountFloat(resultFee).toString()
  }
}