import React, { useCallback, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { ErrorNotification } from '../../../../components/Notification'
import { Button, NavigationButton } from '../../../../components/Button'
import { getAssetAmountFloat, getEastAmountFloat, roundNumber } from '../../../../common/utils'
import { DescriptionBlock, GrayDescriptionBlock } from '../../common/styled-components'
import { PrimaryModal, ModalStatus } from '../../../../components/modal/Modal'
import { useStores } from '../../../../hooks/useStores'
import { Amount } from './styled-components'
import { observer } from 'mobx-react-lite'
import BigNumber from 'bignumber.js'
import { useGetRefillModalData } from './useGetRefillModalData'
import { EastOpType } from '../../../../types/interfaces'
import { AddressWithCopyBlock } from '../../../../components/AddressWithCopyBlock'

export type AssetsAmounts = { assetId: string, rechargeAmount: string, totalAmount?: string }

type IProps = {
  isOpen: boolean,
  eastAmount?: string,
  onSuccess: () => void | Promise<any>,
  onClose: () => void,
  type: EastOpType,
  assetId: string,
  assetAmount?: string,
}

export const RefillBalanceModal = observer((props: IProps) => {
  const {
    isOpen,
    eastAmount,
    onClose,
    onSuccess,
    assetId,
    type,
    assetAmount = '0',
  } = props
  const { wavesConfigStore, wavesBalancesStore, wavesSignStore } = useStores()
  const [inProgress, setInProgress] = useState(false)
  const refillData = useGetRefillModalData(type, assetId, assetAmount)
  const { rechargeAssetsAmounts, isFee } = useMemo(() => refillData, [assetId, assetAmount])

  const checkBalance = async () => {
    setInProgress(true)

    const isBalancesUpdated = rechargeAssetsAmounts.every(asset => {
      if (asset.assetId === wavesConfigStore.eastAssetId) {
        const currentEastBalance = getEastAmountFloat(wavesBalancesStore.eastBalance)
        return asset.totalAmount && new BigNumber(currentEastBalance).isGreaterThanOrEqualTo(asset.totalAmount)
      }
      const currentBalance = getAssetAmountFloat(
        wavesBalancesStore.getAssetBalanceById(asset.assetId),
        wavesConfigStore.getDecimalsByAssetId(asset.assetId))
      return asset.totalAmount && new BigNumber(currentBalance).isGreaterThanOrEqualTo(asset.totalAmount)
    })

    if (isBalancesUpdated) {
      await onSuccess()
      onClose()
    } else {
      toast(<ErrorNotification title={'Lack of funds. Please add to continue.'} />, {
        hideProgressBar: true,
      })
    }
    setInProgress(false)
  }

  const onPrevClicked = useCallback(() => {
    onClose()
  }, [onClose])

  const amounts = rechargeAssetsAmounts.map((el, i) => {
    const isLastItem = rechargeAssetsAmounts.length - 1 === i
    return <Amount key={el.assetId}>
      {roundNumber(el.rechargeAmount)} {wavesConfigStore.getAssetNameById(el.assetId)}
      {!isLastItem && <span> and </span>}
    </Amount>
  })

  let description = null
  if (isFee) {
    description = <DescriptionBlock>
      There is not enough tokens to pay a transaction fee.
      Please add at least {amounts} to your wallet.
    </DescriptionBlock>
  } else {
    description = <DescriptionBlock>
      Lack of funds. Add {amounts} {eastAmount && ` to your wallet to get ${eastAmount} EAST`}
    </DescriptionBlock >
  }

  if (!isOpen) {
    return null
  }

  return <PrimaryModal
    header={isFee ? 'not enough tokens' : 'Add tokens to Address'}
    status={ModalStatus.warning}
    footer={
      <>
        <NavigationButton onClick={onPrevClicked} />
        <Button
          customType={'primary'}
          onClick={checkBalance}
          disabled={inProgress}
          showSpinner={inProgress}
        >
          I’ve sent the transaction
        </Button>
      </>
    }
  >
    {description}
    <AddressWithCopyBlock address={wavesSignStore.address} />
    <GrayDescriptionBlock>
      Transfers between different blockchains sometimes take up to a couple of hours
    </GrayDescriptionBlock>
  </PrimaryModal>
})

