import React, { FC, ReactNode } from 'react'
import styled, { css, keyframes, FlattenSimpleInterpolation } from 'styled-components'
import { devices } from './devices'
import { Icon } from './icon'
import { ButtonProps } from './Button'
import arrowsIcon from '../resources/images/double-arrow-left.svg'

type TSidebarPosition = 'left' | 'right'

const hideInfoFromRight = keyframes`
  from {
    right: 0;
  }
  to {
    right: -350px;
  }
`

const showInfoFromRight = keyframes`
  from {
    right: -350px;
  }
  to {
    right: 0;
  }
`

const hideInfoFromLeft = keyframes`
  from {
    left: 0;
  }
  to {
    left: -350px;
  }
`

const showInfoFromLeft = keyframes`
  from {
    left: -350px;
  }
  to {
    left: 0;
  }
`

const animationCondition = (isOpen: boolean, skipAnimation: boolean, position: TSidebarPosition) => {
  let animation = isOpen ? showInfoFromRight : hideInfoFromRight
  if (position === 'left') {
    animation = isOpen ? showInfoFromLeft : hideInfoFromLeft
  }
  if (skipAnimation) {
    return
  }

  return css`${animation} 250ms ease forwards`
}

const getPosition = (isOpen: boolean): Record<TSidebarPosition, FlattenSimpleInterpolation> => ({
  'left': css`
    left: ${isOpen ? '0' : '-350px'};
  `,
  'right': css`
    right: ${isOpen ? '0' : '-350px'};
  `,
})

export const SidebarWrapper = styled.div<{ isOpen: boolean, skipAnimation: boolean, position: TSidebarPosition }>`
  height: 80vh;
  box-sizing: border-box;
  background: ${props => props.theme.primaryBgColor};
  padding: 0 20px 20px;
  width: 350px;
  position: fixed;
  bottom: 0;
  ${props => getPosition(props.isOpen)[props.position]};
  animation: ${({ isOpen, skipAnimation, position }) => animationCondition(isOpen, skipAnimation, position)};
  border-radius: ${({ position }) => position === 'right' ? '15px 0 0 0' : '0 15px 0 0'};

  @media ${devices.mobile} {
    display: none;
  }
`

export const CollapseButtonWrapper = styled.div<{position: TSidebarPosition}>`
  position: absolute;
  top: 60px;
  ${({ position }) => position === 'right' ? css`
    left: -28px;
  ` : css`
    right: -28px;
  `}
  height: 40px;
  background-color: ${props => props.theme.primaryBgColor};
  cursor: pointer;
  border-radius: ${({ position }) => position === 'right' ? '5px 0 0 5px' : '0 5px 5px 0'};

  display: flex;
  justify-content: center;
  align-items: center;
`

export const CollapseButtonIcon = styled(Icon) <{ isOpen: boolean, position: TSidebarPosition }>`
  ${({ position, isOpen }) => !!(position === 'right' && isOpen || position === 'left' && !isOpen) && css`
    transform: rotate(180deg);
  `}
`

type CollapseButtonProps = {
  isOpen: boolean,
  onClick: () => void,
  position: TSidebarPosition,
} & ButtonProps

export const CollapseButton = (props: CollapseButtonProps) => (
  <CollapseButtonWrapper onClick={props.onClick} position={props.position}>
    <CollapseButtonIcon
      size={28}
      backgroundImage={arrowsIcon}
      isOpen={props.isOpen}
      position={props.position}
    />
  </CollapseButtonWrapper>
)

export type SidebarProps = {
  isOpen: boolean,
  skipAnimation: boolean,
  onToggleIsOpen: () => void,
  position: TSidebarPosition,
  innerRef?: React.Ref<HTMLDivElement>,
  hideScrollBar?: boolean,
  title?: string | ReactNode,
}

const ItemsContainer = styled.div<{ hideScrollBar?: boolean }>`
  height: calc(80vh - 50px);
  overflow-y: scroll;
  position: relative;

  ::-webkit-scrollbar {
    width: ${({ hideScrollBar }) => hideScrollBar ? 0 : '5px'};
    height: ${({ hideScrollBar }) => hideScrollBar ? 0 : '5px'};
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(90, 90, 90);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2);
  }
`

const SidebarTitle = styled.div`
  background: ${props => props.theme.primaryBgColor};
  font-weight: 700;
  font-size: 16px;
  position: sticky;
  top: 0;
  left: 0;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Sidebar: FC<SidebarProps> = (props) => {
  const { isOpen, skipAnimation, onToggleIsOpen, children, position, innerRef, hideScrollBar, title } = props
  return <SidebarWrapper isOpen={isOpen} skipAnimation={skipAnimation} position={position} ref={innerRef}>
    <CollapseButton
      onClick={onToggleIsOpen}
      isOpen={isOpen}
      position={position}
    />
    {title && <SidebarTitle>{title}</SidebarTitle>}
    <ItemsContainer hideScrollBar={hideScrollBar}>
      {children}
    </ItemsContainer>
  </SidebarWrapper>
}