import styled, { css } from 'styled-components'
import { IconProps, defaultSize } from './types'

export const Icon = styled.div<IconProps>`
  width: ${props => props.size || defaultSize}px;
  height: ${props => props.size || defaultSize}px;
  background-repeat: no-repeat;
  background-size: ${props => props.size || defaultSize}px;
  background-image: url(${props => props.backgroundImage});
  margin: 0 auto;

  ${props => props.onClick && css`
      cursor: pointer;

      :hover {
        opacity: 0.8;
      }

      :active {
        opacity: 0.6;
      }
    `
}
`