import BigNumber from 'bignumber.js'
import theme from '../../../../components/theme'

export enum CollateralLevelName {
  high = 'high',
  normal = 'normal',
  low = 'low'
}

export const addEastButtonStyles = {
  color: '#fff',
  background: theme.purpleGradient,
  marginLeft: '8px',
  width: 'fit-content',
}

export const getCollateralLevelName = (vaultBr: string, vaultLr: string) => {
  if (new BigNumber(vaultBr).isGreaterThan(vaultLr)) {
    return CollateralLevelName.high
  } else {
    return CollateralLevelName.low
  }
}

export const getCollateralGradient = (levelName: CollateralLevelName) => {
  switch (levelName) {
    case CollateralLevelName.high:
      return ['#3660E9', '#3660E9']
    case CollateralLevelName.low:
      return ['#F0212B', '#FA676E']
    case CollateralLevelName.normal:
    default:
      return ['#DE9000', '#B57500']
  }
}


