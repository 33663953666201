import React from 'react'
import styled from 'styled-components'
import { PrimaryModal } from '../../../../../components/modal/Modal'
import { GradientText } from '../../../../../components/Text'
import { useNavigate } from 'react-router-dom'
import { DescriptionBlock } from '../../../common/styled-components'

const TextItem = styled(GradientText)`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.3rem;
  cursor: pointer;
`

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const telegramLink = 'https://t.me/wavesenterprisegroup'

export const FAQModal = () => {
  const navigate = useNavigate()

  return <PrimaryModal header={'FAQ'} onClose={() => navigate(-1)}>
    <DescriptionBlock>
          Here you can find articles about how EAST works, why is it safe and everything else.
          If you have any questions, feel free to contact us: <a href={telegramLink} rel={'noreferrer'} target='_blank'>Telegram</a>
    </DescriptionBlock>
    <ItemsContainer>
      <TextItem><a href={'https://east.finance/faq#what-is-east'} target={'_blank'} rel="noreferrer">What is EAST?</a></TextItem>
      <TextItem><a href={'https://east.finance/faq#how-to-use-east'} target={'_blank'} rel="noreferrer">How to use EAST?</a></TextItem>
      <TextItem><a href={'https://east.finance/faq#why-can-i-trust-east'} target={'_blank'} rel="noreferrer">Why can I trust EAST?</a></TextItem>
      <TextItem><a href={'https://east.finance/faq#how-does-east-maintain-its-stability'} target={'_blank'} rel="noreferrer">How does EAST maintain it’s stability?</a></TextItem>
      <TextItem><a href={'https://east.finance/faq#what-does-vault-mean'} target={'_blank'} rel="noreferrer">What does vault mean?</a></TextItem>
      <TextItem><a href={'https://east.finance/faq#what-is-orient'} target={'_blank'} rel="noreferrer">What is ORIENT?</a></TextItem>
      <TextItem><a href={'https://east.finance/faq#how-can-i-earn-with-east'} target={'_blank'} rel="noreferrer">How can I earn with EAST?</a></TextItem>
    </ItemsContainer>
  </PrimaryModal>
}