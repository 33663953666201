import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

type TIntersectionObserverProps = {
  onIntersect: () => void,
}

const Block = styled.div`
  height: 30px;
`

export const IntersectionObserverBlock = (props: TIntersectionObserverProps) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0]
        if (entry.isIntersecting) {
          props.onIntersect()
        }
      },
    )
    if (ref.current) {
      observer.observe(ref.current)
    }
  }, [])

  return <Block ref={ref} />
}