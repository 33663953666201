import { EastOpType } from '../types/interfaces'

export type IServiceConfigResponse = {
  chainId: string,
  coordinatorContractId: string,
  nodeAddress: string,
}

export class EvaluateResponseError {
  error
  address
  expr
  message
  constructor(data: EvaluateError) {
    this.error = data.error
    this.address = data.address
    this.expr = data.expr
    this.message = data.message
  }
}

export type EvaluateResult = {
  result: { value: { _2: { value: Record<string, { type: string, value: number }> } } },
}

export type EvaluateError = {
  error: number,
  address: string,
  expr: string,
  message: string,
}

export type WavesContractConfigItem = { key: string, type: string, value: string }

export type AssetProfits = {
  lastXDays: string,
  total: string,
}

export type TNodeTransactionStatus = 'succedeed' | 'script_execution_failed'

export type TNodeInvokeScriptTransactionInfo = {
  type: 16,
  id: string,
  timestamp: number,
  sender: string,
  senderPublicKey: string,
  dApp: string,
  applicationStatus: TNodeTransactionStatus,
  call: {
    function: EastOpType,
    args: Array<{type: string, value: string}>,
  },
  stateChanges: {
    data: Array<{
      key: string,
      type: 'string',
      value: string,
    }>,
    transfers: Array<{
        address: string,
        asset: string,
        amount: number,
    }>,
  },
  payment: Array<{
    assetId: string | null,
    amount: number,
  }>,
}

export type TNodeTransferTransactionInfo = {
  type: 4,
  id: string,
  timestamp: number,
  sender: string,
  recipient: string,
  senderPublicKey: string,
  applicationStatus: TNodeTransactionStatus,
  amount: number,
  assetId: string,
}

export type TBalanceResponse = {
  assetId: string,
  balance: number,
}

export type TAssetDetails = {
  quantity: number,
}