import styled from 'styled-components'

export const Wrapper = styled.span`
  white-space: nowrap;
`

const TokenBalance = styled.div<{ fontSize: number, letterSpacing?: number, color?: string }>`
  display: inline;
  font-size: ${({ fontSize }) => `${fontSize}px`};
  letter-spacing: ${({ letterSpacing }) => `${letterSpacing || 2}px`};
  font-family: Staatliches;
  color: ${({ color, theme }) => `${color || theme.primaryColor}`};
  white-space: nowrap;
  line-height: 1em;
`

export const IntegerPart = styled(TokenBalance)``

export const FracPart = styled(TokenBalance)`
  opacity:  0.6;
`

export const Prefix = styled(TokenBalance)`
  margin-right: 4px;
`
export const Postfix = styled(TokenBalance)`
  margin-left: 4px;
`