import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'
import { devices } from './devices'
import { CopyIcon } from './icon'
import copy from 'copy-to-clipboard'
import { InfoNotification } from './Notification'
import { toast } from 'react-toastify'
import { truncateString } from '../common/helpers'

type AddressWithCopyBlockProps = {
  noBg?: boolean,
  address: string,
  fontSize?: number,
  length?: number,
}

export const AddressWrapper = styled.div<{fontSize?: number, noBg?: boolean}>`
  border-radius: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${props => (props.fontSize || 15) + 'px'};

  ${({ noBg }) => !noBg && css`
    background: ${props => props.theme.secondaryBgColor};
    padding: 8px 16px;
  `}
`

const FullAddressBlock = styled.div`
  font-family: Cairo,sans-serif;
  font-weight: bold;
  line-height: 16px;
  color: ${props => props.theme.primaryColor};

  @media screen and (${devices.mobile}) {
    font-size: 18px;
    line-height: 22px;
    word-break: break-all;
  }

  @media ${devices.mobile} {
    display: none;
  }
`

const TruncatedAddressBlock = styled(FullAddressBlock)`
  display: none;

  @media ${devices.mobile} {
    display: block;
  }
`

export const useOnCopyClicked = (text: string) => {
  return useCallback(() => {
    copy(text)
    toast(<InfoNotification title={'Copied!'} />, {
      hideProgressBar: true,
    })
  }, [text])
}

export const AddressWithCopyBlock = (props: AddressWithCopyBlockProps) => {
  const { length = 26, address, ...restProps } = props
  const onCopyClicked = useOnCopyClicked(address)

  if (!address) {
    return null
  }

  return <>
    <AddressWrapper {...restProps}>
      <FullAddressBlock>{address}</FullAddressBlock>
      <TruncatedAddressBlock>{truncateString(address, length)}</TruncatedAddressBlock>
      <div><CopyIcon size={24} onClick={onCopyClicked} /></div>
    </AddressWrapper>
  </>
}