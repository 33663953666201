import React, { FunctionComponent } from 'react'
import { ThemeProvider } from 'styled-components'
import theme from './components/theme'
import StyleGlobal from './resources/styles/styles-global'
import { BrowserRouter } from 'react-router-dom'
import { stores, StoresProvider } from './stores/StoresProvider'
import { ErrorBoundary } from './modules/account/ErrorBoundary'
import { AppRouter } from './router/AppRouter'
import { ToastContainer } from './components/ToastContainer'
import duration from 'dayjs/plugin/duration'
import dayjs from 'dayjs'
import { WalletManager } from './modules/account/WalletManager'
dayjs.extend(duration)

const App: FunctionComponent = () => {
  return (
    <BrowserRouter>
      <StoresProvider value={stores}>
        <StyleGlobal />
        <ErrorBoundary>
          <ThemeProvider theme={theme}>
            <ToastContainer />
            <WalletManager />
            <AppRouter />
          </ThemeProvider>
        </ErrorBoundary>
      </StoresProvider>
    </BrowserRouter>
  )
}

export default App
