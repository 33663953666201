import React from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { Container, Delimiter, MenuItemBlock, MenuItemContainer, Tooltip } from './styled-components'
import { MenuItemProps, useMenuItems } from './menuItems'

const MenuItem = (props: MenuItemProps) => {
  const navigate = useNavigate()

  const item = <MenuItemContainer isMobileOnly={Boolean(props.isMobileOnly)}>
    <Tooltip>{props.text}</Tooltip>
    <MenuItemBlock
      onClick={() => navigate(props.path)}
      {...props}
    >
      {props.text}
    </MenuItemBlock>
  </MenuItemContainer>

  if (props.withDelimeters) {
    return <>
      <Delimiter />
      {item}
      <Delimiter />
    </>
  }

  return item
}

export const AccountMenu = observer(() => {
  const menuItems = useMenuItems()

  return <Container>
    {Object.entries(menuItems).map(([path, data]) => (
      <MenuItem key={path} {...data} path={path} />
    ))}
  </Container>
})
