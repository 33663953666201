import styled from 'styled-components'
import bg from '../../../resources/images/main-bg.png'
import eastLogo from '../../../resources/images/east-logo.svg'
import { devices } from '../../../components/devices'

export const FlexWrapper = styled.div<{gap?: number}>`
  display: flex;
  align-items: center;

  gap: ${props => `${props.gap}px`};
`

export const SpaceBetweenWrapper = styled(FlexWrapper)`
  justify-content: space-between;
`

export const CenteredColumnWrapper = styled(FlexWrapper)`
  align-items: center;
  flex-direction: column;
`

export const CenteredContainer = styled.div`
  margin: 0 auto;
`

export const DescriptionBlock = styled.div`
  text-align: center;
  font-size: 15px;
  line-height: 20px;
`

export const GrayDescriptionBlock = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: ${props => props.theme.tertiaryColor};
  text-align: center;
`

export const FeeBlock = styled(GrayDescriptionBlock)`
  font-size: 13px;
`

export const CenteredText = styled.div`
  text-align: center;
`

export const CustomText = styled.div<{ size?: number, weight?: number, color?: string, lineHeight?: number, textAlign?: string, opacity?: number }>`
  font-size: ${({ size }) => size && `${size}px`};
  font-weight: ${({ weight }) => weight && `${weight}`};
  color: ${({ color }) => color && `${color}`};
  line-height: ${({ lineHeight }) => lineHeight && (lineHeight > 5 ? `${lineHeight}px` : `${lineHeight}rem`)};
  text-align: ${({ textAlign }) => textAlign && `${textAlign}`};
  opacity: ${({ opacity }) => opacity && `${opacity}`};
`

export const SubText = styled.div<{ offset?: number, mobileOffset?: number }>`
  display: block;
  font-weight: 400;
  font-size: 15px;
  line-height: 1;
  color: ${props => props.theme.tertiaryColor};
  position: absolute;
  bottom: 0;
  bottom: ${props => `${-(props?.offset || 3)}px`};
  transform: translateY(100%);

  @media ${devices.mobile} {
    bottom: ${props => `${-(props?.mobileOffset || props?.offset || 3)}px`};
    font-size: 13px;
  }
`

export const SubTextRight = styled(SubText)`
  right: 0;
`

export const SubTextLeft = styled(SubText)`
  left: 0;
`

export const BlackoutWrapper = styled.div<{width?: string}>`
  background-color: ${props => props.theme.secondaryBgColor};
  padding: 16px;
  border-radius: 8px;
  color: ${props => props.theme.primaryColor};

  width: ${({ width }) => width};
`

export const CalculatedEastBlock = styled(BlackoutWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  h3, p {
    display: inline-block;
    padding: 0;
    margin: 0;
  }

  h3 {
    font-family: 'Staatliches';
    font-weight: 400;
    letter-spacing: 3px;
    font-size: 32px;
    background: linear-gradient(180deg, #1CB686 0%, #009C6C 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    line-height: 28px;
  }

  p {
    color: ${props => props.theme.tertiaryColor};
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    padding: 10px 0 0;
  }
`

export const IconCommon = styled.div`
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-size: 32px;
`

export const ClippedText = styled.div`
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
`

export const BackgroundWrapper = styled.div`
  position: fixed;
  object-fit: contain;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  background: center / cover no-repeat url(${bg});
`

export const EastLogo = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  width: 174px;
  height: 55px;
  background-image: url(${eastLogo});
  opacity: 0.6;

  @media ${devices.mobile} {
    display: none;
  }
`
