import { getRouteEastStats, getRouteGetOvercollateral, getRouteIssue, getRouteSettings, getRouteStaking, getRouteTransactions, getRouteTransfer } from '../../../consts/routes'
import iconPlus from '../../../resources/images/icon-plus.svg'
import iconLock from '../../../resources/images/icon-lock.svg'
import iconTransfer from '../../../resources/images/icon-transfer.svg'
import iconSettings from '../../../resources/images/icon-settings.svg'
import iconStake from '../../../resources/images/icon-stake.svg'
import { useStores } from '../../../hooks/useStores'

export type MenuItemProps = {
  text: string,
  path: string,
  icon?: string,
  disabled?: boolean,
  backgroundColor?: string,
  withDelimeters?: boolean,
  isMobileOnly?: boolean,
}

const menuItems = {
  [getRouteIssue()]: {
    text: 'Issue EAST',
    icon: iconPlus,
    backgroundColor: 'rgba(54, 96, 233, 1)',
  },
  [getRouteGetOvercollateral()]: {
    text: 'Get Overcollateral',
    icon: iconLock,
    disabled: true,
  },
  [getRouteTransfer()]: {
    text: 'Transfer',
    icon: iconTransfer,
    disabled: true,
  },
  [getRouteStaking()]: {
    text: 'Stake EAST',
    icon: iconStake,
    withDelimeters: true,
    disabled: true,
  },
  [getRouteSettings()]: {
    text: 'Settings',
    icon: iconSettings,
  },
  [getRouteEastStats()]: {
    text: 'Stats',
    isMobileOnly: true,
  },
  [getRouteTransactions()]: {
    text: 'Transactions',
    isMobileOnly: true,
  },
}

export const useMenuItems = (): Record<string, Omit<MenuItemProps, 'path'>> => {
  const { wavesVaultStore, wavesSignStore, wavesBalancesStore, wavesStakingStore } = useStores()

  if (wavesSignStore.address) {
    const { eastBalance, orientBalance, stakedEastBalance } = wavesBalancesStore
    const { vaultEastProfit } = wavesVaultStore
    const isTranferDisabled = +eastBalance <= 0
    const isStakingDisabled = !(+orientBalance || +wavesStakingStore.orientStakedAmount ||
      +stakedEastBalance || +vaultEastProfit || +eastBalance)
    const isClaimDisabled = +vaultEastProfit <= 0
    menuItems[getRouteGetOvercollateral()].disabled = isClaimDisabled
    menuItems[getRouteTransfer()].disabled = isTranferDisabled
    menuItems[getRouteStaking()].disabled = isStakingDisabled
  }

  return menuItems
}