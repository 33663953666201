import BigNumber from 'bignumber.js'
import { useStores } from '../../../../hooks/useStores'
import { minus } from '../../../../common/math'
import { EastOpType } from '../../../../types/interfaces'
import { getAssetAmountFloat, getAssetAmountLong, getEastAmountFloat, getEastAmountLong } from '../../../../common/utils'

export const useGetRefillModalData = (opType: EastOpType, assetId: string, assetAmount: string) => {
  const { wavesConfigStore, wavesBalancesStore } = useStores()
  const rechargeAssetsAmounts: Record<string, { rechargeAmount: string, totalAmount?: string }> = {}
  let isFee = false

  if (!assetId || !assetAmount) {
    return {
      rechargeAssetsAmounts: [],
    }
  }

  if (assetId === wavesConfigStore.eastAssetId) {
    const eastAmountLong = new BigNumber(getEastAmountLong(assetAmount))
    const eastBalance = new BigNumber(wavesBalancesStore.eastBalance)
    if (eastBalance.isLessThan(eastAmountLong)) {
      const rechargeEastAmount = minus(eastAmountLong, eastBalance)
      rechargeAssetsAmounts[assetId] = {
        rechargeAmount: rechargeEastAmount.toString(),
        totalAmount: eastAmountLong.toString(),
      }
      isFee = true
    }
    const mappedAssetAmount = Object.entries(rechargeAssetsAmounts)
      .map(([assetId, { rechargeAmount, totalAmount = 0 }]) => ({
        assetId,
        rechargeAmount: getEastAmountFloat(rechargeAmount),
        totalAmount: getEastAmountFloat(totalAmount),
      }))

    return {
      rechargeAssetsAmounts: mappedAssetAmount,
      isFee,
    }
  }

  let assetAmountLong = new BigNumber(getAssetAmountLong(assetAmount, wavesConfigStore.getDecimalsByAssetId(assetId)))
  const fee = getAssetAmountLong(wavesConfigStore.getFeeByOpType(opType))
  const assetBalance = new BigNumber(wavesBalancesStore.getAssetBalanceById(assetId))
  if (assetId !== wavesConfigStore.feeAssetId) {
    const feeAssetBalance = new BigNumber(wavesBalancesStore.getAssetBalanceById(wavesConfigStore.feeAssetId))
    if (feeAssetBalance.isLessThan(fee)) {
      const rechargeFeeAmount = minus(fee, feeAssetBalance)
      rechargeAssetsAmounts[wavesConfigStore.feeAssetId] = {
        rechargeAmount: rechargeFeeAmount.toString(),
        totalAmount: fee,
      }
    }
    if (assetBalance.isLessThan(assetAmountLong)) {
      const rechargeAssetAmount = minus(assetAmountLong, assetBalance)
      rechargeAssetsAmounts[assetId] = {
        rechargeAmount: rechargeAssetAmount.toString(),
        totalAmount: assetAmountLong.toString(),
      }
    }
  } else {
    assetAmountLong = assetAmountLong.plus(fee)
    if (assetBalance.isLessThanOrEqualTo(assetAmountLong)) {
      const rechargeAssetAmount = minus(assetAmountLong, assetBalance)
      rechargeAssetsAmounts[assetId] = {
        rechargeAmount: rechargeAssetAmount.toString(),
        totalAmount: assetAmountLong.toString(),
      }
    }
    isFee = +rechargeAssetsAmounts[wavesConfigStore.feeAssetId]?.rechargeAmount <= +fee
  }

  const mappedAssetAmount = Object.entries(rechargeAssetsAmounts)
    .map(([assetId, { rechargeAmount, totalAmount = 0 }]) => ({
      assetId,
      rechargeAmount: getAssetAmountFloat(rechargeAmount, wavesConfigStore.getDecimalsByAssetId(assetId)),
      totalAmount: getAssetAmountFloat(totalAmount, wavesConfigStore.getDecimalsByAssetId(assetId)),
    }))

  return {
    rechargeAssetsAmounts: mappedAssetAmount,
    isFee,
  }
}