import React from 'react'
import { toast } from 'react-toastify'
import { ErrorNotification } from '../../components/Notification'
import { useStores } from '../../hooks/useStores'
import { InvokeArgs } from '@waves/signer'

export const useSendExhangeTx = () => {
  const { wavesConfigStore, wavesSignStore, wavesBalancesStore } = useStores()

  const sendExchangeTx = async () => {
    const oldEastBalance = wavesBalancesStore.getAssetBalanceById(wavesConfigStore.oldEastAssetId)
    const closeVaultTx: InvokeArgs = {
      dApp: wavesConfigStore.exchangeContractId,
      call: {
        function: 'exchange',
        args: [],
      },
      payment: [
        {
          assetId: wavesConfigStore.oldEastAssetId,
          amount: oldEastBalance,
        },
      ],
    }
    const result = await wavesSignStore.sendInvokeTx(closeVaultTx, {
      watchTx: false,
    })
    return result
  }

  return { sendExchangeTx }
}

export const useExchangeOldEast = () => {
  const { sendExchangeTx } = useSendExhangeTx()

  const exchangeOldEast = async () => {
    const result = await sendExchangeTx()
    try {
      console.log('Broadcast EXCHANGE vault result:', result)
      return result
    } catch (e: any) {
      console.error('Broadcast EXCHANGE vault error:', e.message)
      toast(<ErrorNotification title={'Error on exchange EAST'} />, {
        hideProgressBar: true,
      })
    }
  }

  return { exchangeOldEast }
}