import React from 'react'
import { MainPageContent } from '../modules/account/Account'
import { ClosePositionModalAsync } from '../modules/account/modals/CloseVault/ClosePositionModal.async'
import { SupplyVaultModalAsync } from '../modules/account/modals/SupplyVault/SupplyVaultModal.async'
import { TransferModalAsync } from '../modules/account/modals/Transfer/Transfer.async'
import { ConnectWalletModal } from '../modules/account/modals/ConnectWalletModal'
import { IssueModalAsync } from '../modules/account/modals/Issue/IssueModal.async'
import { GetOvercollateralModalAsync } from '../modules/account/modals/GetOvercollateral/GetOvercollateralModal.async'
import { SettingsModal } from '../modules/account/modals/Settings/SettingsModal'
import { ExchangeOldEastModal } from '../modules/account/modals/ExchangeOldEast/ExchangeOldEastModal'
import { AppRoutesProps } from './types'
import { ExchangeEastPageAsync } from '../modules/exchangeEast/ExchangeEastPage.async'
import { ModalLoader, PageLoader } from '../components/Loader'
import { NotFoundPage } from '../modules/account/NotFoundPage'
import {
  AppRoutes,
  getRouteClosePosition,
  getRouteConnectWallet,
  getRouteEastStats,
  getRouteExchange,
  getRouteGetOvercollateral,
  getRouteIssue,
  getRouteMain,
  getRouteSettings,
  getRouteStaking,
  getRouteSupply,
  getRouteSwapEast,
  getRouteTransactions,
  getRouteTransfer,
} from '../consts/routes'
import { StakeEastAsync } from '../modules/account/modals/StakeEast/StakeEast.async'
import { EastStatsModalAsync } from '../modules/account/modals/EastStatsModal/EastStatsModal.async'
import { TransactionHistoryModalAsync } from '../modules/account/modals/TransactionsHistoryModal/TransactionHistoryModal.async'

export const routeConfig: Record<AppRoutes, AppRoutesProps> = {
  [AppRoutes.MAIN]: {
    path: getRouteMain(),
    element: <MainPageContent />,
  },
  [AppRoutes.ISSUE]: {
    path: `${getRouteIssue()}/*`,
    element: <IssueModalAsync />,
    withSuccessPath: true,
    fallbackLoader: <ModalLoader />,
    authOnly: true,
  },
  [AppRoutes.SUPPLY]: {
    path: `${getRouteSupply()}/*`,
    element: <SupplyVaultModalAsync />,
    withSuccessPath: true,
    fallbackLoader: <ModalLoader />,
    authOnly: true,
  },
  [AppRoutes.GET_OVERCOLLATERAL]: {
    path: `${getRouteGetOvercollateral()}/*`,
    element: <GetOvercollateralModalAsync />,
    fallbackLoader: <ModalLoader />,
    authOnly: true,
    withSuccessPath: true,
  },
  [AppRoutes.TRANSFER]: {
    path: `${getRouteTransfer()}/*`,
    element: <TransferModalAsync />,
    withSuccessPath: true,
    fallbackLoader: <ModalLoader />,
    authOnly: true,
  },
  [AppRoutes.SETTINGS]: {
    path: `${getRouteSettings()}/*`,
    element: <SettingsModal />,
  },
  [AppRoutes.CLOSE_POSITION]: {
    path: `${getRouteClosePosition()}/*`,
    element: <ClosePositionModalAsync />,
    fallbackLoader: <ModalLoader />,
    authOnly: true,
    withSuccessPath: true,
  },
  [AppRoutes.STAKING]: {
    path: `${getRouteStaking()}/*`,
    element: <StakeEastAsync />,
    fallbackLoader: <ModalLoader />,
    authOnly: true,
    withSuccessPath: true,
  },
  [AppRoutes.CONNECT_WALLET]: {
    path: getRouteConnectWallet(),
    element: <ConnectWalletModal />,
  },
  [AppRoutes.SWAP_EAST]: {
    path: getRouteSwapEast(),
    element: <ExchangeOldEastModal />,
    authOnly: true,
  },
  [AppRoutes.EXCHANGE]: {
    path: getRouteExchange(),
    element: <ExchangeEastPageAsync />,
    fallbackLoader: <PageLoader />,
    withoutLayout: true,
    authOnly: true,
  },
  [AppRoutes.EAST_STATS]: {
    path: getRouteEastStats(),
    element: <EastStatsModalAsync />,
    fallbackLoader: <ModalLoader />,
    mobileOnly: true,
  },
  [AppRoutes.TRANSACTIONS]: {
    path: getRouteTransactions(),
    element: <TransactionHistoryModalAsync />,
    fallbackLoader: <ModalLoader />,
    authOnly: true,
    mobileOnly: true,
  },
  [AppRoutes.NOT_FOUND]: {
    path: '*',
    element: <NotFoundPage />,
    withoutLayout: true,
  },
}
