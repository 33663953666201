import { useState } from 'react'
import { useStores } from '../../../../hooks/useStores'
import axios from 'axios'
import { divide, multiply, plus } from '../../../../common/math'
import { mulp } from '@wavesenterprise/east-math'
import { getEastAmountFloat, roundNumber } from '../../../../common/utils'
import { WAVES_ASSET_ID } from '../../../../consts/constants'

const USDT_ASSET_ID = '9wc3LXNA4TEBsXyKtoLE9mrbDD7WMHXvXrCjZvabLAsi'
const PROD_EAST_ASSET_ID = '6phK22ztGBW127gUFmdMEHKB3CVd6ZhWox2WtwJkbqTq'
const WX_MATCHER_URL = 'https://matcher.waves.exchange'
const SWOPFI_API = 'https://backend.swop.fi'

type TInfoItem = { amount: string, title: string, postfix?: string }

export type TEastStats = {
  prices: {
    wx: TInfoItem,
    swop: TInfoItem,
  },
  totalCollateral: TInfoItem[],
  stats: {
    issued: TInfoItem,
    br: TInfoItem,
    staked: TInfoItem,
    apr: TInfoItem,
  },
}

const initialStats: TEastStats = {
  prices: {
    wx: { amount: '0', title: 'WX (EAST/USDT)' },
    swop: { amount: '0', title: 'Swop.fi (EAST/WEST)' },
  },
  totalCollateral: [
    { amount: '0', title: WAVES_ASSET_ID },
    { amount: '0', title: WAVES_ASSET_ID },
    { amount: '0', title: WAVES_ASSET_ID },
    { amount: '0', title: WAVES_ASSET_ID },
  ],
  stats: {
    issued: { amount: '0', title: 'EAST ISSUED' },
    br: { amount: '0', title: 'EAST BR', postfix: '%' },
    staked: { amount: '0', title: 'EAST STAKED' },
    apr: { amount: '0', title: 'EAST APR', postfix: '%' },
  },
}

export const useEastStats = () => {
  const { wavesConfigStore, api, ratesStore } = useStores()
  const [stats, setStats] = useState<TEastStats>(initialStats)
  const [isLoaded, setLoaded] = useState(false)

  const getWxPrice = async () => {
    const { data: { lastPrice: wxLastPrice } } = await axios.get(`${WX_MATCHER_URL}/matcher/orderbook/${PROD_EAST_ASSET_ID}/${USDT_ASSET_ID}/status#getOrderBookStatusT`)
    return wxLastPrice.toString()
  }

  const getSwopfiPrice = async () => {
    const { data: { data: swopfiRates } } = await axios.get(`${SWOPFI_API}/assets/rates`)
    return swopfiRates[PROD_EAST_ASSET_ID].rate
  }

  const getAssetsTotalSupply = async () => {
    const totalSupplyPromises = Object.entries(wavesConfigStore.assets).map(([assetId, assetData]) => {
      return api.getAssetTotalSupplyByAssetId(assetId)
        .then(assetAmount => ({
          amount: mulp(assetAmount, ratesStore.getRateByAssetId(assetId)).toString(),
          title: assetData.assetName,
        }))
    })
    const assetsTotalSupply = await Promise.all(totalSupplyPromises)
    return assetsTotalSupply
  }

  const getEastAprRate = async () => {
    const eastAprRate = await api.getEastAPR()
    return multiply(eastAprRate, 100).toFixed(1)
  }

  const getEastIssued = async () => {
    const { quantity } = await api.getAssetDetailsByAssetId(wavesConfigStore.eastAssetId)
    return getEastAmountFloat(quantity)
  }

  const getStats = async () => {
    setLoaded(false)
    try {
      const [
        wxPriceResult,
        swopfiPriceResult,
        assetsTotalSupplyResult,
        eastStakedResult,
        eastAprResult,
        eastIssuedResult,
      ] = await Promise.allSettled([
        getWxPrice(),
        getSwopfiPrice(),
        getAssetsTotalSupply(),
        api.getEastStaked(),
        getEastAprRate(),
        getEastIssued(),
      ])

      const stats = initialStats

      if (wxPriceResult.status === 'fulfilled') {
        stats.prices.wx.amount = divide(wxPriceResult.value, 1e8).toFixed(3)
      }
      if (swopfiPriceResult.status === 'fulfilled') {
        stats.prices.swop.amount = divide(swopfiPriceResult.value, 1e6).toFixed(3)
      }
      if (assetsTotalSupplyResult.status === 'fulfilled') {
        stats.totalCollateral = assetsTotalSupplyResult.value
      }
      if (eastStakedResult.status === 'fulfilled') {
        stats.stats.staked.amount = eastStakedResult.value
      }
      if (eastAprResult.status === 'fulfilled') {
        stats.stats.apr.amount = eastAprResult.value
      }
      if (eastIssuedResult.status === 'fulfilled') {
        stats.stats.issued.amount = eastIssuedResult.value
      }


      const sumAssetsSupply = stats.totalCollateral.reduce((acc, { amount }) => {
        return plus(acc, amount).toNumber()
      }, 0)

      const eastBr = roundNumber(divide(sumAssetsSupply, stats.stats.issued.amount).multipliedBy(100), 0)
      stats.stats.br.amount = eastBr
      setStats(stats)
    } catch (e) {
      console.error(e)
    } finally {
      setLoaded(true)
    }
  }

  return { isLoaded, stats, getStats }
}