import React from 'react'
import styled from 'styled-components'
import { PrimaryModal } from '../../../components/modal/Modal'
import keeperIcon from '../../../resources/images/keeper-icon.svg'
import ledgerIcon from '../../../resources/images/ledger-icon.svg'
import seedIcon from '../../../resources/images/seed-icon.svg'
import metamaskIcon from '../../../resources/images/metamask-icon.svg'
import emailIcon from '../../../resources/images/email-icon.svg'
import { NavigationButton } from '../../../components/Button'
import { useLocation, useNavigate } from 'react-router-dom'
import { BlackoutWrapper, DescriptionBlock } from '../common/styled-components'
import { observer } from 'mobx-react-lite'
import { getRouteMain, getRouteSwapEast } from '../../../consts/routes'
import { useStores } from '../../../hooks/useStores'
import { ErrorNotification } from '../../../components/Notification'
import { toast } from 'react-toastify'
import { Providers } from '../../../stores/waves/WavesSignStore'
import { useStoreManager } from '../../../stores/useStoreManager'
import { devices } from '../../../components/devices'

const ProvidersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media ${devices.mobile} {
    padding: 0 10px;
  }
`

const ProviderWrapper = styled(BlackoutWrapper)`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 16px;

  img {
    margin: 0 5px;
    height: 32px;
    width: 32px;
  }
`

const ProviderInfoBlock = styled.div`
  width: 70%;

  h3 {
    padding: 0;
    margin: 0;
    font-size: 15px;
    line-height: 25px;
    color: ${props => props.theme.primaryColor};
  }
`

type ProviderProps = {
  title: string,
  icon: string,
}

const providers: Record<Providers, ProviderProps> = {
  [Providers.PROVIDER_KEEPER]: {
    title: 'Keeper Wallet',
    icon: keeperIcon,
  },
  [Providers.PROVIDER_KEEPER_MOBILE]: {
    title: 'Keeper Mobile',
    icon: keeperIcon,
  },
  [Providers.PROVIDER_CLOUD]: {
    title: 'WX Email',
    icon: emailIcon,
  },
  [Providers.PROVIDER_WEB]: {
    title: 'WX Seed or Key',
    icon: seedIcon,
  },
  [Providers.PROVIDER_LEDGER]: {
    title: 'Ledger',
    icon: ledgerIcon,
  },
  [Providers.PROVIDER_METAMASK]: {
    title: 'MetaMask',
    icon: metamaskIcon,
  },
}

type ProviderBlockProps = {
  onConnect: () => void,
} & ProviderProps

const ProviderBlock = observer((props: ProviderBlockProps) => {
  const { icon, title, onConnect } = props
  const { wavesConfigStore } = useStores()
  const isConfigLoaded = !wavesConfigStore.isConfigLoaded

  // const onInstallClick = () => {
  //   window.open(installExtentionLink)
  // }

  return <ProviderWrapper>
    <img src={icon} />
    <ProviderInfoBlock>
      <h3>{title}</h3>
    </ProviderInfoBlock>
    <NavigationButton direction='right' showSpinner={isConfigLoaded} onClick={onConnect} />
  </ProviderWrapper>
})

export const ConnectWalletModal = observer(() => {
  const { wavesConfigStore, wavesSignStore, wavesBalancesStore } = useStores()
  const navigate = useNavigate()
  const location = useLocation()
  const { onConnectProvider } = useStoreManager()

  const assetsNames = Object.values(wavesConfigStore.assets)
    .map(asset => asset.assetName)
    .join(', ')

  const onConnectNetwork = async (selectedProvider: Providers) => {
    try {
      const user = await wavesSignStore.login(selectedProvider)
      if (user?.address) {
        const { address } = user
        await onConnectProvider(address)
        const { oldEastAssetId } = wavesConfigStore
        const oldEastBalance = wavesBalancesStore.getAssetBalanceById(oldEastAssetId)
        if (+oldEastBalance > 0) {
          navigate(getRouteSwapEast(), { state: location.state })
        } else {
          navigate(location?.state?.from || getRouteMain())
        }
      }
    } catch (e: any) {
      console.error(e)
      const message = e.message?.slice(e.message.indexOf('Details:'), e.message.indexOf('More info:'))
      toast(<ErrorNotification title={`${providers[selectedProvider].title} connection error`} message={message} />)
    }
  }

  return <>
    <PrimaryModal>
      <DescriptionBlock>Issue EAST with {assetsNames}</DescriptionBlock>
      <ProvidersWrapper>
        {Object.entries(providers).map(([key, value]) => (
          <ProviderBlock key={key} {...value} onConnect={() => onConnectNetwork(key as Providers)} />
        ))}
      </ProvidersWrapper>
    </PrimaryModal>
  </>
})