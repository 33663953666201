import React from 'react'
import { toast } from 'react-toastify'
import { ErrorNotification, InfoNotification } from '../../../../components/Notification'
import { useStores } from '../../../../hooks/useStores'
import { InvokeArgs } from '@waves/signer'

export const useClaimAndStakeOrient = () => {
  const { wavesSignStore, wavesConfigStore } = useStores()

  const sendClaimAndStakeOrient = async () => {
    try {
      const repayTx: InvokeArgs = {
        dApp: wavesConfigStore.orientWestingContractId,
        call: {
          function: 'claim',
          args: [],
        },
        payment: [],
      }
      const result = await wavesSignStore.sendInvokeTx(repayTx, {
        assetName: 'ORIENT',
      })
      console.log('Broadcast ClaimAndStake orient result:', result)
      toast(<InfoNotification title={'Orient will be claimed and staked after the transaction is completed. It may take a few minutes.'} />, {
        hideProgressBar: true,
      })
    } catch (e: any) {
      console.error('Broadcast ClaimAndStake orient error: ', e.message)
      toast(<ErrorNotification title={ 'Error on claimAndStake ORIENT'} />, {
        hideProgressBar: true,
      })
    }
  }

  return { sendClaimAndStakeOrient }
}