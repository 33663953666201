import styled, { css } from 'styled-components'
import { devices } from '../../../components/devices'

export const Container = styled.div`
  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);

  display: inline-flex;
  background: ${props => props.theme.secondaryBgColor};
  backdrop-filter: blur(12px);
  border-radius: 54px 54px 0px 0px;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 12px;
  gap: 20px;
  z-index: 2;

  @media ${devices.mobile} {
    position: relative;
    flex-direction: column;
    background: none;
    backdrop-filter: none;
  }

  @media (orientation: landscape) {
    margin-top: 50px;
  }
`

export const Tooltip = styled.div`
  display: none;
  position: absolute;
  padding: 2px 8px;
  background: ${props => props.theme.primaryColor};
  width: fit-content;
  border-radius: 4px;
  margin: auto;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  color:  ${props => props.theme.tertiaryColor};
  z-index: 99999;
  
  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-width: 8px;
    border-style: solid;
    border-color: ${props => props.theme.primaryColor} transparent transparent transparent;
    bottom: -16px;
    left: calc(50% - 8px);
  }
`

export const MenuItemContainer = styled.div<{isMobileOnly: boolean}>`
  position: relative;

  ${({ isMobileOnly }) => isMobileOnly && css`
    display: none;

    @media ${devices.mobile} {
      display: block;
    }
  `}

  &:hover ${Tooltip} {
    display: block;
  }

  @media ${devices.mobile} {
    &:hover ${Tooltip} {
      display: none;
    }
  }
`

type MenuItemProps = {
  icon?: string,
  disabled?: boolean,
  backgroundColor?: string,
}

export const MenuItemBlock = styled.div<MenuItemProps>`
  background: ${props => props.backgroundColor || '#8599E9'};
  background-image: ${props => `url(${ props.icon })` };
  opacity: 0.9;
  border-radius: 112px;
  width: min(74px, 10vw);
  height: min(64px, 8.64vw);
  background-repeat: no-repeat;
  background-size: 40%, 100%;
  background-position: center;
  transition: transform 150ms;
  color: transparent;

  ${({ disabled }) => disabled ?
    css`
      opacity: 0.4;
      pointer-events: none;
    ` :
    css`
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
      }
  `
}

  @media ${devices.mobile} {
    width: 80vw;
    height: fit-content;
    padding: 15px;
    background-image: none;
    color: ${props => props.theme.primaryColor};
    border-radius: 5px;
    font-weight: 700;
    font-size: 18px;
  }
`

export const Delimiter = styled.div`
  width: 2px;
  height: 32px;
  background: #FFFFFF;
  opacity: 0.4;
  border-radius: 4px;

  @media ${devices.mobile} {
    display: none;
  }
`

