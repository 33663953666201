/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { EastOpType } from '../../../../types/interfaces'
import { Block } from '../../../../components/Block'
import styled, { css } from 'styled-components'
import { shineBatch } from '../../../../components/Animations'
import { BlackoutWrapper } from '../styled-components'
import { devices } from '../../../../components/devices'
import { useStores } from '../../../../hooks/useStores'
import { getTrimmedAssetAmount, getTrimmedEastAmount, getUppercased, truncateString } from '../../../../common/helpers'
import { TNodeInvokeScriptTransactionInfo, TNodeTransactionStatus, TNodeTransferTransactionInfo } from '../../../../api/ApiInterfaces'

const ItemColumn = styled.div`
  text-align: left;
`

const ItemContainer = styled(BlackoutWrapper)<{ status?: TNodeTransactionStatus }>`
  display: grid;
  grid-template-columns: 1.5fr 2fr;
  height: 98px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 8px;
  cursor: pointer;

  ${props => props.status === 'script_execution_failed' && css`
    background-color: rgba(126, 8, 8, 0.5);
  `}

  @media only screen and (${devices.mobile}) {
    cursor: default;
    gap: 8px;
    align-items: flex-start;
  }

  transition: filter 250ms ease-in-out;
  :hover {
    filter: brightness(.9);
  }
`

export const TxItemSkeleton = styled(ItemContainer)<{height?: number}>`
  height: ${props => props.height && props.height + 'px'};
  background-image: ${({ theme }) => `linear-gradient(90deg, ${theme.secondaryBgColor} 8%, ${theme.primaryBgColor} 18%, ${theme.secondaryBgColor} 33%)`};
  background-size: 576px;
  animation: ${shineBatch} 1.6s infinite linear;
`

const PrimaryText = styled.div`
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
`

const SecondaryText = styled.div`
  font-size: 15px;
  color: ${props => props.theme.primaryColor};
  word-break: break-word;
  line-height: 1;
`

const Time = styled.div`
  font-size: 15px;
  line-height: 18px;
  color: ${props => props.theme.blue};
`

export const TxItem = (props: TNodeInvokeScriptTransactionInfo | TNodeTransferTransactionInfo) => {
  const { wavesConfigStore, appConfigStore, wavesSignStore } = useStores()
  const [primaryText, setPrimaryText] = useState('')
  const [description, setDescription] = useState('')
  const date = dayjs(props.timestamp).format('MMM D HH:mm')

  const getAssetName = (assetId: string) => {
    return wavesConfigStore.getAssetNameById(assetId)
  }

  useEffect(() => {
    if (props.type === 16) {
      const getNormalizedArgs = () => {
        return props.call.args.reduce((acc, el) => ({
          ...acc, [el.type]: el.value,
        }), {} as Record<string, string>) || {}
      }

      const getPayment = () => {
        return props.payment.map(el => {
          const assetId = el.assetId || wavesConfigStore.feeAssetId
          return {
            amount: getTrimmedAssetAmount(el.amount, wavesConfigStore.getDecimalsByAssetId(assetId)),
            assetId,
            name: getAssetName(assetId),
          }
        })
      }

      let primaryText: string = ''
      let description: string = props?.call?.function || ''
      const args = getNormalizedArgs()
      switch (props?.call?.function) {
        case EastOpType.mint:
          const mintedEastAmount = props.stateChanges.transfers[0].amount
          primaryText = `+${getTrimmedEastAmount(mintedEastAmount)} EAST`
          description = getPayment().map(({ name, amount }) => `Locked ${amount} ${name}`).join(';') || ''
          break

        case EastOpType.close:
          if (!props.payment?.length) {
            const assetId = args.string
            const assetName = getAssetName(assetId)
            primaryText = `-${getTrimmedAssetAmount(args.integer, wavesConfigStore.getDecimalsByAssetId(assetId))} ${assetName}`
            description = 'Claim overpay'
            break
          }
          if (!args.string || !args.integer) {
            primaryText = getPayment().map(({ amount, name }) => `-${amount} ${name}`).join(';') || ''
            description = 'Repay'
            break
          }
          primaryText = getPayment().map(({ name, amount }) => `-${amount} ${name}`).join(';') || ''
          const assetId = args.string
          const assetName = getAssetName(assetId)
          description = `Vault closed. ${getTrimmedAssetAmount(args.integer, wavesConfigStore.getDecimalsByAssetId(assetId))} ${assetName} unlocked.`
          break

        case EastOpType.supply:
          primaryText = getPayment().map(({ name, amount }) => `+${amount} ${name}`).join(';') || ''
          break

        case EastOpType.reissue:
          primaryText = `+${getTrimmedEastAmount(args.integer)} EAST`
          break

        case EastOpType.stake:
          primaryText = getPayment().map(({ name, amount }) => `-${amount} ${name}`).join(';') || ''
          break

        case EastOpType.unstake:
          const unstakedEastAmt = props.stateChanges.transfers[0].amount
          primaryText = `+${getTrimmedEastAmount(unstakedEastAmt)} EAST`
          description = getPayment().map(({ name, amount }) => `Unstake ${amount} ${name}`).join(';') || ''
          break
      }

      setPrimaryText(primaryText)
      setDescription(description)
      return
    }

    if (props.type === 4) {
      let primaryText: string = ''
      let description: string = ''
      if (props.sender === wavesSignStore.address) {
        primaryText = `-${getTrimmedEastAmount(props.amount)} EAST`
        description = `Transfer to ${truncateString(props.recipient, 20)}`
      }
      if (props.recipient === wavesSignStore.address) {
        primaryText = `+${getTrimmedEastAmount(props.amount)} EAST`
        description = `Transfer from ${truncateString(props.sender, 20)}`
      }
      setPrimaryText(primaryText)
      setDescription(description)
    }

  }, [])

  const onExplorerLinkClicked = (txId: string) => {
    const link = appConfigStore.getExplorerTxLink(txId)
    window.open(link, '_blank')
  }

  return <ItemContainer onClick={() => onExplorerLinkClicked(props.id)} status={props.applicationStatus}>
    <ItemColumn>
      <PrimaryText>{primaryText}</PrimaryText>
      <Block marginTop={8}>
        <Time>{date}</Time>
      </Block>
    </ItemColumn>
    <ItemColumn>
      <SecondaryText>{getUppercased(description)}</SecondaryText>
    </ItemColumn>
  </ItemContainer>
}
