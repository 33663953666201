import React from 'react'
import styled, { FlattenSimpleInterpolation, css } from 'styled-components'
import { Icon } from './Icon'
import reloadIcon from '../../resources/images/reload-icon.svg'
import { CustomIconProps } from './types'

type IconReloadProps = {
  offset?: number,
  position: 'left' | 'right',
} & CustomIconProps

const getPosition = (offset: IconReloadProps['offset'] = 0): Record<IconReloadProps['position'], FlattenSimpleInterpolation> => ({
  'left': css`
    left: ${`${offset}px`};
  `,
  'right': css`
    right: ${`${offset}px`};
  `,
})

export const IconReload = styled(Icon)<IconReloadProps>`
  margin: unset;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${props => getPosition(props.offset)[props.position]}
`

export const ReloadIcon = (props: IconReloadProps) => {
  return <IconReload backgroundImage={reloadIcon} {...props} />
}