import React from 'react'
import styled from 'styled-components'
import { PrimaryModal } from '../../../../components/modal/Modal'
import { Route, useNavigate, Routes } from 'react-router-dom'
import { useStore } from '../../../../hooks/useStores'
import { Link } from '../../../../components/Link'
import { observer } from 'mobx-react-lite'
import { FAQModal } from './FAQ/FAQModal'
import { AddressWithCopyBlock } from '../../../../components/AddressWithCopyBlock'
import { getRouteMain } from '../../../../consts/routes'
import { useStoreManager } from '../../../../stores/useStoreManager'

const MAIL_ADDRESS = 'support@east.finance'

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
`

const Option = styled.div`
  display: block;
  font-family: Cairo,sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  padding-bottom: 6px;
  color: ${props => props.theme.blue};
	cursor: pointer;
	position: relative;
	transition: color 250ms ease;

	::after {
		content: '';
		position: absolute;
		width: 100%;
		background-color: #434197;
		height: 2px;
		left: 0;
		right: 0;
		bottom: 2px;
    background-color: transparent;
		transition: background-color 250ms ease;
  }

  :hover {
    ::after {
		  background-color: #434197;
    }
	}
`

const OptionLink = Option.withComponent(Link)

enum SubPaths {
  ROOT = '',
  FAQ = 'faq',
  SUPPORT = 'support'
}

const Settings = observer(() => {
  const wavesSignStore = useStore('wavesSignStore')
  const navigate = useNavigate()
  const { onDisconnectProvider } = useStoreManager()

  const onChangeAccountClick = async () => {
    await wavesSignStore.logout()
    onDisconnectProvider()
    navigate(getRouteMain())
  }

  return <PrimaryModal>
    <AddressWithCopyBlock address={wavesSignStore.address} />
    <OptionsContainer>
      {/* <Option onClick={() => routeTo(SubPaths.support)}>Get Help</Option> */}
      <OptionLink href={`mailto:${MAIL_ADDRESS}`}>Get Help</OptionLink>
      <Option onClick={() => navigate(SubPaths.FAQ)}>Check FAQ</Option>
    </OptionsContainer>
    {wavesSignStore.address &&
          <OptionsContainer>
            <Option onClick={onChangeAccountClick}>Change account</Option>
          </OptionsContainer>
    }
  </PrimaryModal>
})

export const SettingsModal = () => {
  return <Routes>
    <Route path={SubPaths.ROOT} element={<Settings />} />
    <Route path={SubPaths.FAQ} element={<FAQModal />} />
  </Routes>
}
