import { RefObject, useEffect } from 'react'

type Handler = (event: MouseEvent) => void

export const useOutsideClick = (ref: RefObject<HTMLElement>, handler: Handler) => {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      // @ts-ignore
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }
      handler(event)
    }
    document.addEventListener('mousedown', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
    }
  }, [ref, handler])
}