import axios from 'axios'
import { makeAutoObservable, runInAction } from 'mobx'
import * as Sentry from '@sentry/react'
import debounce from 'lodash.debounce'
import { sizes } from '../components/devices'

const emptyAppConfig = { clientAddress: '', clientAddressWaves: '', heapMetricsId: '', yandexMetricsId: '', sentryDsn: '' }

export default class AppConfigStore {
  appConfig = emptyAppConfig
  showStats = true
  isMobile = window.innerWidth < sizes.mobileWidth

  constructor() {
    makeAutoObservable(this)
    this.loadInitialConfig()
    this.setDetectIsMobileListener()
  }

  setShowStats = (value: boolean) => {
    this.showStats = value
  }

  setDetectIsMobileListener() {
    const updateSize = () => {
      runInAction(() => {
        this.isMobile = window.innerWidth < sizes.mobileWidth || window.screen.orientation.angle >= 90
      })
    }
    window.addEventListener('resize', debounce(updateSize, 250))
  }

  initMetrics(heapMetricsId: string, yandexMetricsId: string) {
    if (heapMetricsId && window.heap) {
      window.heap.load(heapMetricsId)
      console.log(`Heap metrics initialized with id = ${heapMetricsId}`)
    }
    if (yandexMetricsId && window.ym) {
      window.ym(yandexMetricsId, 'init', {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
      })
      console.log(`Yandex metrics initialized with id = ${yandexMetricsId}`)
    }
  }

  initSentry(dsn: string) {
    Sentry.init({
      dsn,
      integrations: [new Sentry.BrowserTracing()],
      tracesSampleRate: 1.0,
    })
  }

  getExplorerTxLink(txId: string) {
    return `${this.appConfig.clientAddressWaves}/transactions/${txId}`
  }

  async loadInitialConfig() {
    try {
      const start = Date.now()
      const { data }: { data: typeof emptyAppConfig } = await axios.get(`/app.config.json?t=${Date.now()}`)
      console.log('app.config.json loaded:', data, ', time elapsed:', Date.now() - start, 'ms')
      runInAction(() => {
        this.appConfig = data
      })
      if (data.heapMetricsId && data.yandexMetricsId) {
        this.initMetrics(data.heapMetricsId, data.yandexMetricsId)
      }
      if (data.sentryDsn) {
        this.initSentry(data.sentryDsn)
      }
    } catch (e: any) {
      console.error('Cannot get app config:', e.message)
    }
  }
}