import styled, { keyframes, css } from 'styled-components'
import { CollateralLevelName, getCollateralGradient } from './helpers'
import { Button } from '../../../../components/Button'
import { Tooltip } from '../../Menu/styled-components'
import { devices } from '../../../../components/devices'

type ContainerProps = {
  isShown: boolean,
  showFadeIn: boolean,
}

const aniTime = '750ms'
const bezier = 'ease'

const FadeInBack = keyframes`
  from{transform: scale(0.7); opacity: 0;}
  to{transform: scale(0.9); opacity: 1;}
`

const collateralFill = (from: number, to: number) => keyframes`
  from { right: ${from}%; }
  to { right: ${to}%; }
`

const BackToFront = keyframes`
  from {transform: scale(0.9,0.9);}
  to {transform: scale(1,1); z-index:10;}
`

const BackToBackAgain = keyframes`
  from {z-index:10; transform: scale(1,1)}
  51% {z-index:-1;}
  to {transform: scale(0.9,0.9);}
`

const animationCondition = ({ isShown, showFadeIn }: ContainerProps) => {
  let animation = isShown ? BackToFront : BackToBackAgain
  if (showFadeIn) {
    animation = FadeInBack
  }
  return css`${animation} ${aniTime} ${bezier} forwards`
}

export const bottomOffsetContainer = '13px'
export const bottomOffsetContainerMobile = '44px'

export const Container = styled.div<ContainerProps>`
  width: ${props => props.theme.cardWidth};
  height: ${props => props.theme.cardHeight};
  box-sizing: border-box;
  position: absolute;
  z-index: -10;
  bottom: -${bottomOffsetContainer};
  transform: scale(0.9);
  border-radius: 10px;
  cursor:  pointer;
  opacity: 1;
  animation: ${props => animationCondition(props)};


  @media ${devices.mobile} {
    width: ${props => props.theme.cardWidthMobile};
    height: ${props => props.theme.cardHeightMobile};
  }
`

export const DetailsBody = styled.div<{ isShown: boolean }>`
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: ${props => props.theme.primaryBgColor};
  box-shadow: 0px 24px 40px rgba(0, 0, 0, 0.4);
`

export const Text = styled.span<{fontSize?: number}>`
  display: block;
  font-size: ${props => `${props.fontSize || 16}px`};
  font-family: Staatliches;
  color: ${props => props.theme.primaryColor};
  opacity: 0.5;
  letter-spacing: 2px;
  font-weight: 300;
`

export const PrimaryText = styled(Text) <{ isBreak?: boolean }>`
  opacity: 1;
  letter-spacing: 3px;
  color: ${props => props.theme.primaryColor};
  ${({ isBreak }) => isBreak && `
      word-break: break-all;
  `}
`

export const ButtonTextWrapper = styled.span<{ gradientText?: boolean }>`
  ${({ gradientText }) => gradientText && `
    background: radial-gradient(204.55% 3032.86% at 67.55% 85.45%, rgba(172, 171, 216, 0) 0%, #514EFF 100%),
    linear-gradient(0deg, #1D87D6, #1D87D6);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
  `}
`

export const LiquidationBlock = styled.div`
  font-family: Cairo, Helvetica,Arial,sans-serif;
  text-transform: uppercase;
  background-color: ${props => props.theme.black15};
  border-radius: 3px;
  padding: 1.5px 6px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.5px;
`

type ProgressBarWrapperProps = {
  prevPercent: number,
  levelName: CollateralLevelName,
  percent: number,
  isShown: boolean,
}

export const ProgressBarWrapper = styled.div<ProgressBarWrapperProps>`
  font-size: 18px;
  font-family: Staatliches;
  height: 39px;
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 20px;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 0 0 10px 10px;
  
  &::before {
    z-index: 1;
    position: absolute;
    content: '';
    left: 0%;
    top: 0%;
    bottom: 0%;
    right: ${props => Math.ceil(100 - props.percent)}%;
    transition: right 800ms ease forwards;
    animation: ${props => collateralFill(Math.ceil(100 - props.prevPercent), Math.ceil(100 - props.percent))} 800ms ease forwards;
    ${({ levelName }) => {
    const [gradientFrom, gradientTo] = getCollateralGradient(levelName)
    return css`
      background: ${`linear-gradient(180deg, ${gradientFrom} 0%, ${gradientTo} 100%)`};
      box-shadow: 0px 4px 12px ${gradientTo};
    `
  }}
  };

  @media ${devices.mobile} {
    height: 48px;
  }
`

export const ProgressBarText = styled.div`
  width: 100%;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
`

export const DetailedButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
`

export const DetailedCardWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const DetailedCardButton = styled(Button)`
  background-color: ${props => props.theme.quaternaryBgColor};
  color: ${props => props.theme.primaryColor};
  line-height: 1;

  ${props => props.disabled && css`
    color: ${props => props.theme.secondaryColor};
    background-color: ${props => props.theme.quaternaryBgColor};
    opacity: 0.6;
  `}
`

export const ReissueButton = styled(DetailedCardButton)`
  padding: 0 10px;
`

export const CloseButton = styled(DetailedCardButton)`
  color: ${props => !props.disabled && props.theme.red};
`

export const SupplyButton = styled(DetailedCardButton)<{ levelName: CollateralLevelName }>`
  background-size: '100%';
  background-repeat: 'repeat';
  ${({ levelName, disabled }) => {
    const [gradientFrom, gradientTo] = getCollateralGradient(levelName)
    if (levelName === CollateralLevelName.high || disabled) {
      return
    }
    return css`
      background: ${`linear-gradient(180deg, ${gradientFrom} 0%, ${gradientTo} 100%)`};
    `
  }}
`

export const QuestionTooltipContainer = styled.div`
  position: relative;

  &:hover ${Tooltip} {
    display: block;
  } 
`

export const DisconnectButton = styled(DetailedCardButton)`
  height: 30px;
`
