import React, { ReactNode, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { devices } from '../devices'
import ReactDOM from 'react-dom'

export type SecondaryModalProps = {
  children: ReactNode,
  onClose: () => void,
}

export const Overlay = styled.div`
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`

export const SecondaryModalWrapper = styled.div`
  display: block;
  position: fixed;
  z-index: 9999;
  width: 310px;
  border-radius: 24px;
  box-shadow: 0px 79px 32px rgba(51, 0, 18, 0.01), 0px 44px 27px rgba(51, 0, 18, 0.05), 0px 20px 20px rgba(51, 0, 18, 0.09), 0px 5px 11px rgba(51, 0, 18, 0.1), 0px 0px 0px rgba(51, 0, 18, 0.1);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background: ${props => props.theme.primaryBgColor};
  color: ${props => props.theme.primaryColor};
  padding: 30px;

  @media only screen and (${devices.mobile}) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
`

export const SecondaryModal = (props: SecondaryModalProps) => {

  const onKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      props.onClose()
    }
  }, [props.onClose])

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)

    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [])

  return ReactDOM.createPortal(
    <Overlay>
      <SecondaryModalWrapper {...props}>
        {props.children}
      </SecondaryModalWrapper>
    </Overlay>,
    document.getElementById('modals-root')!)
}
