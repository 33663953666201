import { createGlobalStyle } from 'styled-components'
import './fonts.css'
import theme from '../../components/theme'

const StyleGlobal = createGlobalStyle`
  html {
    box-sizing: border-box;
    background: #f4f4f4;
  }
  
  body {
    margin: 0;
    padding: 0;
    font-size: 13px;
    color: ${theme.secondaryColor};
    font-family: Cairo, Helvetica, Arial, sans-serif;
    font-variant: normal;
  }

  .hide {
    display: none !important;
  }

  .show {
    display: block;
  }

  body.no-scroll,
  html.no-scroll {
    overflow: hidden;
  }
  
  //html, body {
  //  width:100vw;
  //  height: 100vh;
  //  margin: 0;
  //}

  a {
    color: #2e8ad7;
    text-decoration: none;
  }

  .toast {
    background-color: ${theme.secondaryBgColor};
  }
`

export default StyleGlobal
