import React, { useEffect } from 'react'
import { DollarIcon, GridWrapper } from './styled-components'
import { BlackoutWrapper } from '../styled-components'
import { TokenBalance } from '../../Card/TokenBalance/TokenBalance'
import dollarSign from '../../../../resources/images/dollar-sign.svg'
import { Description } from '../../Card/FrontCard/styled-components'
import { useEastStats } from './useEastStats'
import { abbreviateNumber } from '../../../../common/utils'
import styled from 'styled-components'
import { TxItemSkeleton } from '../TransactionList/TxRows'
import { observer } from 'mobx-react-lite'
import { useStores } from '../../../../hooks/useStores'

type InfoProps = {
  value: string,
  description: string,
  iconUrl?: string,
  postfix?: string,
}

const StatsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  :last-child {
    margin-bottom: 24px;
  }
`

const StatsBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const StatsTitle = styled.div`
  font-weight: 700;
  font-size: 14px;
`

const InfoBlock = (props: InfoProps) => (
  <BlackoutWrapper>
    {props.iconUrl && <DollarIcon backgroundImage={props.iconUrl} />}
    <TokenBalance integerPartSize={24} postfix={props.postfix}>{props.value}</TokenBalance>
    <Description>{props.description}</Description>
  </BlackoutWrapper>
)

const EastStatsLoader = styled(TxItemSkeleton)`
  margin-bottom: 0;
`

export const EastStatsInfo = observer((props: ReturnType<typeof useEastStats>) => {
  const { wavesConfigStore } = useStores()
  const { isLoaded, stats, getStats } = props

  const { prices, totalCollateral, stats: eastStats } = stats

  const pricesValues = Object.values(prices)
  const eastStatsValues = Object.values(eastStats)

  useEffect(() => {
    if (wavesConfigStore.eastAssetId && wavesConfigStore.isConfigLoaded) {
      getStats()
    }
  }, [wavesConfigStore.eastAssetId, wavesConfigStore.isConfigLoaded])

  return <StatsWrapper>
    <StatsBlock>
      <StatsTitle>Current Price</StatsTitle>
      <GridWrapper>
        {isLoaded ? pricesValues.map(el =>
          <InfoBlock key={el.title} value={el.amount} description={el.title} iconUrl={dollarSign} />) :
          Array(pricesValues.length).fill(null).map((_, index) => <EastStatsLoader height={90} key={index} />)
        }
      </GridWrapper>
    </StatsBlock>
    <StatsBlock>
      <StatsTitle>Total Collateral</StatsTitle>
      <GridWrapper>
        {isLoaded ? totalCollateral.map(el =>
          !Number.isNaN(+el.amount) &&
            <InfoBlock
              key={el.title}
              value={abbreviateNumber(+el.amount)}
              description={el.title}
              iconUrl={dollarSign}
            />,
        ) :
          Array(totalCollateral.length).fill(null).map((_, index) => <EastStatsLoader height={75} key={index} />)
        }
      </GridWrapper>
    </StatsBlock>
    <StatsBlock>
      <StatsTitle>EAST Stats</StatsTitle>
      <GridWrapper>
        {isLoaded ? eastStatsValues.map(el =>
          <InfoBlock
            key={el.title}
            value={abbreviateNumber(+el.amount)}
            description={el.title}
            postfix={el.postfix}
          />,
        ) :
          Array(eastStatsValues.length).fill(null).map((_, index) => <EastStatsLoader height={75} key={index} />)
        }
      </GridWrapper>
    </StatsBlock>
  </StatsWrapper>
})