import React from 'react'
import { AccountMenu } from '../Menu/Menu'
import { AccountInner, AccountWrapper, SelectNetworkButton } from './styled-components'
import { useNavigate } from 'react-router-dom'
import { getRouteConnectWallet } from '../../../consts/routes'
import { TxsProgressBar } from './TxsProgressBar'
import { Card } from '../Card'
import { useStore } from '../../../hooks/useStores'
import { EastLogo } from '../common/styled-components'
import { observer } from 'mobx-react-lite'
import { Sidebars } from './Sidebars'
// import { OrientNotification } from './OrientNotification'

const SelectNetworkButtonBlock = observer(() => {
  const { address } = useStore('wavesSignStore')
  const navigate = useNavigate()

  if (address) {
    return null
  }

  return <>
    <SelectNetworkButton
      customType='primary'
      onClick={() => navigate(getRouteConnectWallet())}
    >
    Connect wallet
    </SelectNetworkButton>
  </>
})

export const MainPageContent = () => {
  return <>
    <EastLogo />
    <AccountWrapper>
      <AccountInner>
        <TxsProgressBar />
        {/* <OrientNotification /> */}
        <Card />
        <SelectNetworkButtonBlock />
      </AccountInner>
    </AccountWrapper>
    <AccountMenu />
    <Sidebars />
  </>
}

