import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { Block } from '../../../../components/Block'
import { Button } from '../../../../components/Button'
import { SecondaryModal } from '../../../../components/modal/SecondaryModal'
import { ErrorNotification, InfoNotification } from '../../../../components/Notification'
import { useStores } from '../../../../hooks/useStores'
import { useToggleModal } from '../../../../hooks/useToggleModal'
import { EastOpType } from '../../../../types/interfaces'
import eastLogo from '../../../../resources/images/east-logo-small.svg'
import { getRouteMain } from '../../../../consts/routes'
import { useSendExhangeTx } from '../../../exchangeEast/useExchangeOldEast'
import { RefillBalanceModal } from '../RefillBalanceModal/RefillBalanceModal'

const Text = styled.div`
  font-size: 15px;
  margin: 20px 0 30px;
  padding: 0 15px;
`

export const ExchangeOldEastModal = () => {
  const { wavesBalancesStore, wavesConfigStore } = useStores()
  const { onOpen, ...restModalProps } = useToggleModal()
  const navigate = useNavigate()
  const txFee = +wavesConfigStore.getFeeByOpType(EastOpType.exchange)
  const { sendExchangeTx } = useSendExhangeTx()
  const location = useLocation()

  const handleSwapClick = async () => {
    if (txFee > +wavesBalancesStore.feeAssetBalanceFloat) {
      onOpen()
      return
    }
    try {
      await sendExchangeTx()
      toast(<InfoNotification title='Transaction has been send' />)
      onClose()
    } catch (e: any) {
      console.error('Broadcast EXCHANGE vault error:', e.message)
      toast(<ErrorNotification title={'Error on exchange EAST'} />, {
        hideProgressBar: true,
      })
    }
  }

  const onClose = () => {
    navigate(location?.state?.from || getRouteMain())
  }

  return <>
    <SecondaryModal onClose={onClose}>
      <img src={eastLogo} />
      <h2>EAST 1.0 → 2.0 swap</h2>
      <Text>You have vaults with EAST 1.0 tokens.
        To save your money, please swap them for EAST 2.0 tokens.
        This swap is commission-free.<br />
        The exchange rate is 1:1.
      </Text>
      <Button customType='primary' onClick={handleSwapClick}>Swap EAST 1.0 for EAST 2.0</Button>
      <Block marginTop={20} />
      <Button customType='default' onClick={onClose}>Remind me later</Button>
    </SecondaryModal>
    <RefillBalanceModal
      {...restModalProps}
      type={EastOpType.exchange}
      assetId={wavesConfigStore.feeAssetId}
      onSuccess={() => sendExchangeTx()}
    />
  </>
}