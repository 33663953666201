import React, { ReactNode, useCallback, useEffect, useRef } from 'react'
import { CrossIcon } from '../icon'
import { useLocation, useNavigate } from 'react-router-dom'
import { getRouteMain } from '../../consts/routes'
import ReactDOM from 'react-dom'
import { EastOpTypeModalTitle } from './EastOpTypeModalTitle'
import { IconContainerHeader, Overlay, PrimaryModalContainer, PrimaryModalContent, PrimaryModalFooter, PrimaryModalHeader, PrimaryModalWrapper } from './styles'

export enum ModalStatus {
  success = 'success',
  warning = 'warning'
}

export type ModalProps = {
  id?: string,
  status?: ModalStatus,
  header?: string | ReactNode,
  footer?: ReactNode | ReactNode[],
  children: ReactNode | ReactNode[],
  onClose?: () => void,
  gap?: number,
}

export const PrimaryModal = (props: ModalProps) => {
  const location = useLocation()
  const pathname = '/' + location.pathname.split('/')[1]
  const {
    gap = 30,
    header = EastOpTypeModalTitle[pathname],
  } = props
  const navigate = useNavigate()
  const overlayRef = useRef<HTMLDivElement>(null)

  const onClose = useCallback(() => {
    if (props.onClose) {
      props.onClose()
      return
    }
    navigate(getRouteMain())
  }, [navigate, props])

  const onKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      onClose()
    }
  }, [onClose])

  useEffect(() => {
    document.body.classList.add('no-scroll')
    window.addEventListener('keydown', onKeyDown)

    return () => {
      document.body.classList.remove('no-scroll')
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [])

  const closeModalOnOutsideClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (overlayRef.current === e.target) {
      onClose()
    }
  }, [onClose])

  return ReactDOM.createPortal(
    <Overlay ref={overlayRef} onMouseDown={closeModalOnOutsideClick} id={props.id}>
      <PrimaryModalContainer>
        <PrimaryModalHeader status={props.status}>
          {header}
          <IconContainerHeader>
            <CrossIcon onClick={onClose} size={'32px'} />
          </IconContainerHeader>
        </PrimaryModalHeader>

        <PrimaryModalWrapper withFooter={!!props.footer}>
          <PrimaryModalContent gap={gap}>
            {props.children}
          </PrimaryModalContent>
        </PrimaryModalWrapper>

        {props.footer &&
            <PrimaryModalFooter>
              {props.footer}
            </PrimaryModalFooter>
        }
      </PrimaryModalContainer>
    </Overlay>,
    document.getElementById('modals-root')!,
  )
}
