import { autorun, makeAutoObservable } from 'mobx'
import { AnyBNType, EastOpType } from '../../types/interfaces'
import { PRECISION } from '../../consts/constants'
import BigNumber from 'bignumber.js'
import { getAssetAmountLong } from '../../common/utils'
import { EastMath, divp, mulp } from '@wavesenterprise/east-math'
import { IVault as ICloseCalcVault } from '@wavesenterprise/east-math/dist/types'
import WavesConfigStore from './WavesConfigStore'
import AppRatesStore from '../AppRatesStore'
import WavesVaultStore from './WavesVaultStore'
import WavesBalancesStore from './WavesBalancesStore'

export default class WavesCalculationsStore {
  private wavesConfigStore: WavesConfigStore
  private wavesBalancesStore: WavesBalancesStore
  private ratesStore: AppRatesStore
  private vaultStore: WavesVaultStore

  constructor(
    wavesConfigStore: WavesConfigStore,
    wavesBalancesStore: WavesBalancesStore,
    ratesStore: AppRatesStore,
    vaultStore: WavesVaultStore,
  ) {
    makeAutoObservable(this)
    this.wavesConfigStore = wavesConfigStore
    this.wavesBalancesStore = wavesBalancesStore
    this.ratesStore = ratesStore
    this.vaultStore = vaultStore

    autorun(() => {
      this.eastMath.setPrices(this.ratesStore.rates)
    })
  }

  private get eastMath() {
    return new EastMath({
      backingRatios: this.wavesConfigStore.assetsBr,
      liquidationRatios: this.wavesConfigStore.assetsLr,
      precision: PRECISION.toString(),
    })
  }

  private get vaultWithNormalizedAssets(): ICloseCalcVault {
    const assets = this.vaultStore.vaultCollateralAssets.reduce(
      (acc, cur) => ({ ...acc, [cur.assetId]: cur.amount }), {},
    )
    return ({
      eastAmount: this.vaultStore.vaultEastAmount,
      assets,
      lastFee: '0',
      lastFraction: '0',
      lastUpdate: '0',
    })
  }

  maxAssetAmount(assetId: string, assetAmount: AnyBNType) {
    return this.eastMath.maxAssetAmount(
      this.vaultWithNormalizedAssets,
      assetId,
      assetAmount,
    )
  }

  eastToBurnToKeepHealth(assetId: string, assetAmount: AnyBNType) {
    return this.eastMath.eastToBurnToKeepHealth(
      this.vaultWithNormalizedAssets,
      assetId,
      assetAmount,
    )
  }

  private countEquivalent(assetId: string, assetAmount: AnyBNType) {
    const assetBr = this.wavesConfigStore.assetsBr[assetId] || '0'
    const assetRate = this.ratesStore.getRateByAssetId(assetId)
    const assetDecimals = this.wavesConfigStore.getDecimalsByAssetId(assetId)
    const eastDecimals = this.wavesConfigStore.getDecimalsByAssetId(this.wavesConfigStore.eastAssetId)
    const decimalsDiff = assetDecimals - eastDecimals
    const assetInUsd = mulp(assetAmount, assetRate).dividedBy(10 ** decimalsDiff)
    let eastAmount = divp(assetInUsd, assetBr)
    if (eastAmount.isNaN()) {
      eastAmount = new BigNumber(0)
    }
    return { eastAmount, assetInUsd }
  }

  calculateEastByAssetAmount(assetId: string, assetAmount: AnyBNType) {
    return this.countEquivalent(assetId, assetAmount).eastAmount
  }

  calculateCollateralAmountUsd(assetId: string, assetAmount: AnyBNType) {
    return this.countEquivalent(assetId, assetAmount).assetInUsd
  }

  calculateAssetAvailable(assetId: string, opType: EastOpType) {
    const totalFee = getAssetAmountLong(this.wavesConfigStore.getFeeByOpType(opType))
    const assetBalance = this.wavesBalancesStore.getAssetBalanceById(assetId)
    let assetAvailable = new BigNumber(assetBalance)
    if (assetId === this.wavesConfigStore.feeAssetId) {
      assetAvailable = assetAvailable.minus(totalFee)
    }
    const result = BigNumber.maximum(assetAvailable, 0)
    return result.toString()
  }

  calculateBR(vaultConfiguration: { assetId: string, assetAmount: AnyBNType, eastAmount: AnyBNType }) {
    const { assetId, assetAmount } = vaultConfiguration
    const eastAmount = new BigNumber(vaultConfiguration.eastAmount)
    const usdEq = this.vaultStore.vaultCollateralAssets.reduce((acc, asset) => {
      if (asset.assetId === assetId && assetAmount) {
        const collateralInUsd = this.calculateCollateralAmountUsd(assetId, assetAmount)
        return acc.plus(collateralInUsd)
      }
      const collateralInUsd = this.calculateCollateralAmountUsd(asset.assetId, asset.amount)
      return acc.plus(collateralInUsd)
    }, new BigNumber(0))
    const result = eastAmount.gt(0) ? divp(usdEq, eastAmount) : BigNumber(Infinity)
    return BigNumber.max(0, result).toString()
  }

  getPercentFromRatio(ratio: string, decimalPlaces = 0) {
    return mulp(ratio, 100).decimalPlaces(decimalPlaces, BigNumber.ROUND_HALF_EVEN).toString()
  }

  get vaultLiquidationRatioPercent() {
    return this.getPercentFromRatio(this.vaultStore.vault.liquidationRatio)
  }

  get vaultBackingRatioPercent() {
    return this.getPercentFromRatio(this.vaultStore.vault.backingRatio)
  }
}