import React, { useEffect, useRef } from 'react'
import { useStores } from '../../hooks/useStores'
import { toast } from 'react-toastify'
import { InfoNotification } from '../../components/Notification'
import { observer } from 'mobx-react-lite'
import { useStoreManager } from '../../stores/useStoreManager'
import { Providers } from '../../stores/waves/WavesSignStore'

export const WalletManager = observer(() => {
  const isListenerSetted = useRef(false)
  const { wavesSignStore, wavesConfigStore, ratesStore } = useStores()
  const isConnected = !!window.KeeperWallet
  const isAssetsLoaded = Boolean(Object.keys(wavesConfigStore.assets).length)
  const { onConnectProvider, onDisconnectProvider } = useStoreManager()

  useEffect(() => {
    const setUser = async () => {
      if (wavesConfigStore.isConfigLoaded) {
        await wavesSignStore.setUserDataFromLs()
        await onConnectProvider(wavesSignStore.address)
      }
    }

    setUser()
  }, [wavesConfigStore.isConfigLoaded])

  useEffect(() => {
    ratesStore.startPollingRates()
  }, [isAssetsLoaded])

  useEffect(() => {
    if (!isListenerSetted.current && isConnected) {
      isListenerSetted.current = true
      window.KeeperWallet.on('update', async (state) => {
        try {
          if (state.network.code !== wavesConfigStore.chainId) {
            await wavesSignStore.logout()
            onDisconnectProvider()
            toast(<InfoNotification message='You have changed the network' />)
            return
          }
          if (wavesSignStore.selectedProviderKey === Providers.PROVIDER_KEEPER &&
            wavesSignStore.address !== state.account?.address) {
            if (state.account) {
              const { address } = state.account
              await onConnectProvider(address)
              toast.dismiss()
              toast(<InfoNotification message='You have changed the active wallet' />)
            }
          }
        } catch (e: any) {
          console.error(e.message)
        }
      })
    }
  }, [isConnected, wavesConfigStore.nodeAddress])

  return null
})