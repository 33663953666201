import BigNumber from 'bignumber.js'

export type AnyBNType = string | BigNumber | number

export enum EastOpType {
  mint = 'mint',
  transfer = 'transfer',
  reissue = 'reissue',
  supply = 'supply',
  close = 'close',
  liquidate = 'liquidate',
  stake = 'stake',
  unstake = 'unstake',
  exchange = 'exchange'
}

export enum OrientOpType {
  claim = 'claim',
  stake = 'stake',
  unstake = 'unstake',
}

const EastOpTypeName: Record<EastOpType, string> = {
  [EastOpType.mint]: 'Mint',
  [EastOpType.transfer]: 'Transfer',
  [EastOpType.close]: 'Close vault',
  [EastOpType.supply]: 'Supply vault',
  [EastOpType.reissue]: 'Reissue vault',
  [EastOpType.liquidate]: 'Liquidate vault',
  [EastOpType.stake]: 'Stake',
  [EastOpType.unstake]: 'Unstake',
  [EastOpType.exchange]: 'Exchange',
}

const OrientOpTypeName: Record<OrientOpType, string> = {
  [OrientOpType.claim]: 'Claim',
  [OrientOpType.stake]: 'Stake',
  [OrientOpType.unstake]: 'Unstake',
}

export type OpType = EastOpType | OrientOpType

export const OpTypeName = {
  ...EastOpTypeName,
  ...OrientOpTypeName,
}

export type ITransaction = {
  height: number,
  txId: string,
  blockchain: Blockchain,
  senderAddress: string,
  recipient: null,
  txType: OpType,
  timestamp: string,
  txData?: Record<string, string>,
  createdAt: string,
  protocol: 'east' | 'orient',
}

export type IVault = {
  eastAmount: string,
  backingRatio: string,
  liquidationRatio: string,
  collateralAssets: CollateralAssetData[],
  stabilityFee: string,
  collateralEastEquivalent: string,
  collateralUsdEquivalent: string,
}

export type CollateralAssetData = {
  assetId: string,
  amount: string,
}

export enum Blockchain {
  WAVES = 'WAVES'
}

export type TFullAssetData = {
  assetId: string,
  assetName: string,
  decimals: number,
  assetBr: string,
  assetLr: string,
  oracleAddress: string,
  ticker: string,
}

export type TAssetData = Omit<TFullAssetData, 'assetId'>

export type TAssets = Record<string, TAssetData>

export type IRates = {
  [key: string]: {
    price: string,
    name: string,
  },
}

export type IRate = {
  assetName: string,
  price: string,
  assetId: string,
}

export type AssetName = 'EAST' | 'ORIENT'
