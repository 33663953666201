export enum AppRoutes {
  MAIN = '',
  ISSUE = 'issue',
  SUPPLY = 'supply',
  GET_OVERCOLLATERAL = 'get-overcollateral',
  TRANSFER = 'transfer',
  SETTINGS = 'settings',
  CLOSE_POSITION = 'close-position',
  STAKING = 'staking',
  CONNECT_WALLET = 'connect-wallet',
  EXCHANGE = 'exchange',
  SWAP_EAST = 'swap-east',
  EAST_STATS = 'stats',
  TRANSACTIONS = 'transactions',
  // last
  NOT_FOUND = 'not-found'
}

export const SUCCESS_PATH = 'success'

const getRoutePath = (route: AppRoutes) => `/${route}`

export const getRouteMain = () => getRoutePath(AppRoutes.MAIN)
export const getRouteIssue = () => getRoutePath(AppRoutes.ISSUE)
export const getRouteSupply = () => getRoutePath(AppRoutes.SUPPLY)
export const getRouteGetOvercollateral = () => getRoutePath(AppRoutes.GET_OVERCOLLATERAL)
export const getRouteTransfer = () => getRoutePath(AppRoutes.TRANSFER)
export const getRouteSettings = () => getRoutePath(AppRoutes.SETTINGS)
export const getRouteClosePosition = () => getRoutePath(AppRoutes.CLOSE_POSITION)
export const getRouteStaking = () => getRoutePath(AppRoutes.STAKING)
export const getRouteConnectWallet = () => getRoutePath(AppRoutes.CONNECT_WALLET)
export const getRouteExchange = () => getRoutePath(AppRoutes.EXCHANGE)
export const getRouteSwapEast = () => getRoutePath(AppRoutes.SWAP_EAST)
export const getRouteEastStats = () => getRoutePath(AppRoutes.EAST_STATS)
export const getRouteTransactions = () => getRoutePath(AppRoutes.TRANSACTIONS)

export const getSuccessRoutePath = (route: AppRoutes) => `/${route}/${SUCCESS_PATH}`
export const getRouteNotFound = () => getRoutePath(AppRoutes.NOT_FOUND)


