import { makeAutoObservable, onBecomeObserved, onBecomeUnobserved, runInAction } from 'mobx'
import { Api } from '../../api'
import WavesConfigStore from './WavesConfigStore'
import { getContractKey } from '../../common/helpers'
import { EvaluateResult } from '../../api/ApiInterfaces'
import BigNumber from 'bignumber.js'
import { SelectOption } from '../../components/Select'
import { PRECISION, STAKING_DATA_POLLING_INTERVAL_MS } from '../../consts/constants'
import { divp, mulp } from '@wavesenterprise/east-math'
import { AnyBNType } from '../../types/interfaces'

const ST_EAST_RATE_KEY = '%s__rate'
const ORIENS_STAKED_AMOUNT_KEY = 'stakedAmount'

type ProfitsData = {
  yesterdayProfit: string,
  totalProfit: string,
}

export default class WavesStakingStore {
  private api: Api
  private wavesConfigStore: WavesConfigStore

  private address = ''

  private pollingStEastRateId: NodeJS.Timeout | null = null
  private pollingOrientStakedAmountId: NodeJS.Timeout | null = null
  private pollingProfitsId: NodeJS.Timeout | null = null

  stEastRate = '0'
  orientStakedAmount = '0'
  orientAddressLockDelta = 0

  profits: Record<string, ProfitsData> | undefined
  apy: string = '0'

  constructor(api: Api, wavesConfigStore: WavesConfigStore) {
    makeAutoObservable(this, {
      startPollingStEastRate: false,
      startPollingOrientStakedAmount: false,
      setOrientAddressLockDelta: false,
      startPollingProfits: false,
      stopPollingByIntervalId: false,
    })
    this.api = api
    this.wavesConfigStore = wavesConfigStore

    onBecomeObserved(this, 'stEastRate', this.startPollingStEastRate)
    onBecomeUnobserved(this, 'stEastRate', () => this.stopPollingByIntervalId(this.pollingStEastRateId))

    onBecomeObserved(this, 'profits', this.startPollingProfits)
    onBecomeUnobserved(this, 'stEastRate', () => this.stopPollingByIntervalId(this.pollingProfitsId))

    // onBecomeObserved(this, 'orientStakedAmount', this.startPollingOrientStakedAmount)
    // onBecomeUnobserved(this, 'orientStakedAmount', () => this.stopPollingByIntervalId(this.pollingOrientStakedAmountId))

    // onBecomeObserved(this, 'orientAddressLockDelta', this.setOrientAddressLockDelta)
  }

  reset() {
    this.stEastRate = '0'
    this.orientStakedAmount = '0'
    this.orientAddressLockDelta = 0
    this.profits = undefined
    this.apy = '0'
    this.stopPollingByIntervalId(this.pollingStEastRateId)
    this.stopPollingByIntervalId(this.pollingProfitsId)
    this.stopPollingByIntervalId(this.pollingOrientStakedAmountId)
  }

  setSelectedAddress(address: string) {
    this.address = address
  }

  calcStEastAmountByEast(eastAmount: AnyBNType) {
    return divp(eastAmount, this.stEastRate)
      .decimalPlaces(0, BigNumber.ROUND_HALF_EVEN)
  }

  calcEastAmountByStEast(stEastAmount: AnyBNType) {
    return mulp(stEastAmount, this.stEastRate)
      .dividedBy(100).decimalPlaces(0, BigNumber.ROUND_HALF_EVEN)
  }

  get stakingAssetsOptions(): SelectOption[] {
    return [
      { value: this.wavesConfigStore.eastAssetId, label: 'EAST' },
      { value: this.wavesConfigStore.orientAssetId, label: 'ORIENT', disabled: true },
    ]
  }

  get unstakingAssetsOptions(): SelectOption[] {
    return [
      { value: this.wavesConfigStore.stEastAssetId, label: 'STEAST' },
      { value: this.wavesConfigStore.orientAssetId, label: 'ORIENT', disabled: true },
    ]
  }

  setOrientAddressLockDelta = async () => {
    try {
      const contractId = this.wavesConfigStore.orientStakingContractId
      const expr = { expr: `getAddressLockDelta("${this.address}")` }
      const lockDeltaResponse = await this.api.evaluate(contractId, expr) as EvaluateResult
      const lockDelta = +lockDeltaResponse.result.value._2.value
      runInAction(() => {
        this.orientAddressLockDelta = lockDelta
      })
    } catch (e: any) {
      console.error('Cannot get orient staking data from contract:', e.message)
    }
  }

  private setStEastRate = async () => {
    try {
      const contractId = this.wavesConfigStore.eastStakingContractId
      const stEastRateResponse = await this.api.getWavesContractDataByKey(contractId, ST_EAST_RATE_KEY)
      const stEastRate = stEastRateResponse.value
      runInAction(() => {
        this.stEastRate = stEastRate
      })
    } catch (e: any) {
      console.error('Cannot get stEast rate from contract:', e.message)
    }
  }

  startPollingStEastRate = async () => {
    this.stopPollingByIntervalId(this.pollingStEastRateId)

    await this.setStEastRate()

    runInAction(() => {
      this.pollingStEastRateId = setInterval(async () => {
        await this.setStEastRate()
      }, STAKING_DATA_POLLING_INTERVAL_MS)
    })
  }

  stopPollingByIntervalId(intervalId: NodeJS.Timeout | null) {
    if (intervalId) {
      clearInterval(intervalId)
    }
  }

  private setOrientStakedAmount = async () => {
    try {
      const contractId = this.wavesConfigStore.orientStakingContractId
      const orientStakedResponse = await this.api.getWavesContractDataByKey(
        contractId,
        getContractKey('%s%s', ORIENS_STAKED_AMOUNT_KEY, this.address),
      )
      const orientStakedAmount = orientStakedResponse.value
      runInAction(() => {
        this.orientStakedAmount = orientStakedAmount
      })
    } catch (e: any) {
      console.error('Cannot get orient staked amount from contract:', e.message)
    }
  }

  startPollingOrientStakedAmount = async () => {
    this.stopPollingByIntervalId(this.pollingOrientStakedAmountId)

    await this.setOrientStakedAmount()

    runInAction(() => {
      this.pollingOrientStakedAmountId = setInterval(async () => {
        await this.setOrientStakedAmount()
      }, STAKING_DATA_POLLING_INTERVAL_MS)
    })
  }

  async setProfitsData() {
    try {
      const profits = await this.api.getProfits(this.address, 1)
      runInAction(() => {
        this.profits = {
          [this.wavesConfigStore.eastAssetId]: {
            yesterdayProfit: profits[this.wavesConfigStore.eastAssetId]?.lastXDays,
            totalProfit: profits[this.wavesConfigStore.eastAssetId]?.total,
          },
          [this.wavesConfigStore.orientAssetId]: {
            yesterdayProfit: profits[this.wavesConfigStore.orientAssetId]?.lastXDays,
            totalProfit: profits[this.wavesConfigStore.orientAssetId]?.total,
          },
        }
      })
    } catch (e: any) {
      console.error(`Error on load staking profits, ${e.message}`)
    }
  }

  startPollingProfits = async () => {
    this.stopPollingByIntervalId(this.pollingProfitsId)

    await this.setProfitsData()

    runInAction(() => {
      this.pollingProfitsId = setInterval(async () => {
        await this.setProfitsData()
      }, STAKING_DATA_POLLING_INTERVAL_MS)
    })
  }

  async getStakingAPYPercent(days?: number) {
    try {
      const { apy } = await this.api.getStakingAPY(days)
      return new BigNumber(apy)
        .multipliedBy(100)
        .dividedBy(PRECISION)
        .decimalPlaces(2)
        .toString()
    } catch (e: any) {
      console.error(`Error on load staking apr, ${e.message}`)
      return '0'
    }
  }

  getProfitsByAssetId(assetId: string) {
    return this.profits?.[assetId] || { yesterdayProfit: '0', totalProfit: '0' }
  }
}