import { BigNumber } from 'bignumber.js'
import { DECIMAL_PLACES, EAST_DECIMAL_PLACES } from '../consts/constants'
import { AnyBNType, divide, multiply } from './math'

export const roundNumber = (
  num: AnyBNType,
  n = 8,
  roundMode: BigNumber.RoundingMode = BigNumber.ROUND_HALF_EVEN,
): string => {
  return new BigNumber(num).decimalPlaces(n, roundMode).toString(10)
}

export const getAssetAmountLong = (assetAmount: AnyBNType, decimals = DECIMAL_PLACES): string =>
  multiply(assetAmount, 10 ** decimals)
    .toString()

export const getAssetAmountFloat = (assetAmountLong: AnyBNType, decimals = DECIMAL_PLACES) =>
  divide(assetAmountLong, 10 ** decimals)
    .decimalPlaces(decimals, BigNumber.ROUND_HALF_EVEN)
    .toString(10)

export const getEastAmountLong = (assetAmount: AnyBNType): string =>
  getAssetAmountLong(assetAmount, EAST_DECIMAL_PLACES)

export const getEastAmountFloat = (eastAmountLong: AnyBNType) =>
  getAssetAmountFloat(eastAmountLong, EAST_DECIMAL_PLACES)

export const cutNumber = (num: string | number, maxFractional = 8): string => {
  const match = num.toString().match(/[.,]/)
  if (match) {
    const [divider] = match
    const [decimal, fractional] = num.toString().split(divider)
    return `${decimal}${divider}${fractional.slice(0, maxFractional)}`
  }
  return num.toString()
}

export const spacifyFractional = (num: string | number): string => {
  return num.toString().replace(/(\d{3})/g, '$1 ').trim()
}

export const spacifyNumber = (num: number | string): string => {
  const match = num.toString().match(/[.,]/)
  if (match?.[0]) {
    const separator = match[0]
    // eslint-disable-next-line prefer-const
    let [integerPart, fractionalPart] = num.toString().split(separator)
    if (fractionalPart) {
      fractionalPart = spacifyFractional(fractionalPart)
    } else {
      return num.toString()
    }
    return integerPart + '.' + fractionalPart
  }
  return num.toString()
}

// Converts number > 1000 to a compact display 1000 => 1K, 1500 => 1.5K, 1000000 => 1M
export const abbreviateNumber = (num: number) => {
  const SI_SYMBOL = ['', 'K', 'M']
  const tier = Math.log10(num) / 3 | 0

  if (tier === 0) {
    return num.toString()
  }

  const suffix = SI_SYMBOL[tier]
  const scale = Math.pow(10, tier * 3)
  const scaled = num / scale

  return cutNumber(scaled, 1) + suffix
}
