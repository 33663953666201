import React from 'react'
import styled from 'styled-components'

export enum NotificationType {
  default = 'default',
  error = 'error'
}

export type IToastCloseButton = {
  type: NotificationType,
  closeToast: () => void,
}

const Container = styled.div<{ type: NotificationType }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: Cairo, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  width: 100%;
  height: 100%;
  color: ${props => props.theme.primaryColor};
  background: ${props => props.theme.secondaryBgColor};
  box-shadow: 0 32px 32px rgb(0 0 0 / 15%);
  border-radius: 4px;
  line-height: 1.2;
`

const MessageTitle = styled.div`
  font-weight: 600;
`
const MessageSubTitle = styled.div`
  font-weight: 300;
  opacity: 0.8;
`

export type IProps = {
  title?: string,
  message?: string,
}

type INotificationProps = {
  type: NotificationType,
} & IProps

const Notification = (props: INotificationProps) => {
  return <Container type={NotificationType.error}>
    {props.title && <MessageTitle>{props.title}</MessageTitle>}
    {props.message &&
      <MessageSubTitle>{props.message}</MessageSubTitle>
    }
  </Container>
}

export const ErrorNotification = (props: IProps) => <Notification {...props} type={NotificationType.error} />

export const InfoNotification = (props: IProps) => <Notification {...props} type={NotificationType.default} />

