import React from 'react'
import { Api } from '../api'
import AppConfigStore from './AppConfigStore'
import AppTransactionsStore from './AppTransactionsStore'
import WavesCalculationsStore from './waves/WavesCalculationsStore'
import WavesConfigStore from './waves/WavesConfigStore'
import WavesSignStore from './waves/WavesSignStore'
import AppRatesStore from './AppRatesStore'
import WavesVaultStore from './waves/WavesVaultStore'
import WavesStakingStore from './waves/WavesStakingStore'
import WavesBalancesStore from './waves/WavesBalancesStore'

const api = new Api()
const appConfigStore = new AppConfigStore()

const wavesConfigStore = new WavesConfigStore(api)
const appTransactionsStore = new AppTransactionsStore(api, wavesConfigStore)
const ratesStore = new AppRatesStore(api, wavesConfigStore)
const wavesSignStore = new WavesSignStore(api, wavesConfigStore, appTransactionsStore)
const wavesVaultStore = new WavesVaultStore(api, wavesConfigStore)
const wavesStakingStore = new WavesStakingStore(api, wavesConfigStore)
const wavesBalancesStore = new WavesBalancesStore(api, wavesConfigStore)

const wavesCalculationsStore = new WavesCalculationsStore(
  wavesConfigStore,
  wavesBalancesStore,
  ratesStore,
  wavesVaultStore,
)

export const stores = {
  api,
  ratesStore,
  appConfigStore,
  appTransactionsStore,
  wavesCalculationsStore,
  wavesConfigStore,
  wavesSignStore,
  wavesVaultStore,
  wavesStakingStore,
  wavesBalancesStore,
}

export const storesContext = React.createContext(stores)
export const StoresProvider = storesContext.Provider