
const theme: Record<string, string> = {
  black: '#0A0606',
  green: '#00A87A',
  greenToxic: '#55D7CE',
  yellow: '#D78C55',
  red: '#F0222B',
  darkBlue: '#043569',
  darkBlue15: 'rgba(4, 53, 105, 0.15)',
  darkBlue07: 'rgba(4, 53, 105, 0.07)',
  lightgray: 'rgba(255, 255, 255, 0.5)',
  black15: 'rgba(0, 0, 0, 0.15)',
  blue: '#4D72E9',

  purpleGradient: 'linear-gradient(90deg, #5352B8 0%, #323177 100%)',
  grayGradient: 'linear-gradient(180deg, #F2F2F2 0%, rgba(242, 242, 242, 0.8) 100%)',
  orangeGradient: 'linear-gradient(180deg, #EF8F00 0%, #FF6240 100%, #FF6240 100%)',

  primaryColor: '#E7EBFB',
  secondaryColor: '#B6C2F2',
  tertiaryColor: '#6D85E5',
  quaternaryColor: '#3660E9',

  primaryBgColor: '#1C233B',
  secondaryBgColor: '#243161',
  tertiaryBgColor: '#3D50A0',
  // on hover
  quaternaryBgColor: '#304080',

  borderColor: 'rgba(61, 80, 160, 1)',

  cardWidth: '444px',
  cardHeight: '260px',

  cardWidthMobile: 'min(444px, 90vw)',
  cardHeightMobile: '280px',
}

export default theme
