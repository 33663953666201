import React from 'react'
import styled from 'styled-components'
import { Button } from '../../components/Button'
import { BackgroundWrapper, CenteredContainer, EastLogo } from './common/styled-components'
import { Block } from '../../components/Block'
import { useNavigate } from 'react-router-dom'
import { getRouteMain } from '../../consts/routes'
import image404 from '../../resources/images/404.svg'
import { devices } from '../../components/devices'

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const ReloadButton = styled(Button)`
  width: fit-content;
  padding: 0 32px;
`

const PageDescription = styled.h1`
  font-size: 16px;
  padding: 0;
`

const NotFoundImg = styled.div`
  background-image: url(${image404});
  height: 250px;
  width: 500px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;


  @media ${devices.mobile} {
    height: 100px;
    width: 200px;
  }
`

export const NotFoundPage = () => {
  const navigate = useNavigate()

  return <Wrapper>
    <BackgroundWrapper />
    <EastLogo />
    <CenteredContainer><NotFoundImg /></CenteredContainer>
    <PageDescription>This page does not exist</PageDescription>
    <Block marginTop={40} />
    <ReloadButton
      customType='primary'
      onClick={() => navigate(getRouteMain())}>
      Go back to EAST client
    </ReloadButton>
  </Wrapper>
}