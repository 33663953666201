import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { useStore } from '../../../hooks/useStores'
import { ORACLE_DECIMALS } from '../../../consts/constants'
import { divide } from '../../../common/math'
import { devices } from '../../../components/devices'

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`

const Container = styled.div`
  margin: 10px 10px 0 0;
  width: fit-content;
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
  gap: 20px;
  z-index: 20;

  @media ${devices.mobile} {
    gap: 10px;
  }
`

const PriceContainer = styled.div`
  text-align: right;
  font-family: Cairo;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.primaryColor};;
  background: ${props => props.theme.primaryBgColor};
  border-radius: 5px;
  padding: 8px;
  display: flex;
  text-transform: uppercase;
  align-items: center;

  box-shadow: 0 6px 15px rgba(0,0,0,0.4);

  span {
    :first-child {
      padding-right: 10px;
      position: relative;

      :after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        content: '';
        display: inline-block;
        height: 80%;
        width: 1.5px;
        background-color: #FFFFFF;
      }
    }

    :last-child {
      padding: 0 5px 0 10px;
    }
  }

`

export const RatesBlock = observer(() => {
  const { rates } = useStore('ratesStore')
  const prices = useMemo(() =>
    Object.values(rates).map(el => ({ ...el, price: divide(el.price, ORACLE_DECIMALS).toString() })), [rates])

  return <Wrapper>
    <Container>
      {prices.map(rate => (
        <PriceContainer key={rate.name}>
          <span>{rate.name}</span>
          <span>{rate.price}</span>
        </PriceContainer>
      ))}
    </Container>
  </Wrapper>
})
