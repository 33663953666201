import React, { HTMLAttributes, ReactNode } from 'react'
import { spacifyFractional } from '../../../../common/utils'
import { FracPart, IntegerPart, Postfix, Prefix, Wrapper } from './styled-components'

export type ITokenBalanceProps = {
  children: string | number,
  integerPartSize: number,
  fracPartSize?: number,
  letterSpacing?: number,
  prefix?: ReactNode,
  postfix?: ReactNode,
  color?: string,
} & HTMLAttributes<HTMLDivElement>

const getTransformedValue = (value: string) => {
  const match = value.toString().match(/[.,]/)
  if (match?.[0]) {
    const separator = match[0]
    // eslint-disable-next-line prefer-const
    let [integerPart, fractionalPart = ''] = value.split(separator)
    if (fractionalPart) {
      fractionalPart = spacifyFractional(fractionalPart)
    }
    return [integerPart, fractionalPart]
  }
  return [value, '']
}

export const TokenBalance = (props: ITokenBalanceProps) => {
  const { integerPartSize, fracPartSize = integerPartSize } = props
  const [integerPart, fractionalPart] = getTransformedValue(props.children.toString())
  return <Wrapper style={props.style} id={props.id}>
    {props.prefix && <Prefix fontSize={integerPartSize} {...props}>{props.prefix}</Prefix>}
    <IntegerPart fontSize={integerPartSize} {...props}>{integerPart}</IntegerPart>
    {fractionalPart &&
      <FracPart fontSize={fracPartSize} {...props}>
        .{fractionalPart}
      </FracPart>
    }
    {props.postfix && <Postfix fontSize={integerPartSize} {...props}>{props.postfix}</Postfix>}
  </Wrapper>
}