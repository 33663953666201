import styled, { keyframes, css } from 'styled-components'
import iconPlus from '../../../../resources/images/plus.png'
import eastLogoSmall from '../../../../resources/images/east-logo-small.svg'
import { devices } from '../../../../components/devices'

type ContainerProps = {
  isShown: boolean,
  showFadeIn: boolean,
}

const aniTime = '750ms'
const bezier = 'ease'

const FadeInFront = keyframes`
  from{transform: scale(0.8); opacity: 0;}
  to{transform: scale(1); opacity: 1;}
`

const FrontToFrontAgain = keyframes`
  from {transform: translate(0,0) scale(0.9,0.9);z-index: 1}
  51% {z-index: 1;}
  53% {transform: translate(0,-250px) scale(0.9,0.9);z-index:10;}
  to {transform: translate(0,0) scale(1,1);z-index:10;}
`

const FrontToBack = keyframes`
  from {transform: translate(0,0) scale(1,1);}
  50% {transform: translate(0,-250px) scale(0.9,0.9);}
  to {transform: translate(0,0)  scale(0.9,0.9); opacity: 0.5;}
`

const animationCondition = ({ isShown, showFadeIn }: ContainerProps) => {
  let animation = isShown ? FrontToFrontAgain : FrontToBack
  if (showFadeIn) {
    animation = FadeInFront
  }
  return css`${animation} ${aniTime} ${bezier} forwards`
}

export const CardWrapper = styled.div`
  transition: transform 250ms ease;
  :hover {
    transform: scale(1.025);
  }
`

export const Container = styled.div<ContainerProps>`
  animation: ${props => animationCondition(props)};
  border-radius: 6px;
  position: relative;
`

export const ContentWrapper = styled.div<{ bgImage: string }>`
  position: relative;
  width: ${props => props.theme.cardWidth};
  height: ${props => props.theme.cardHeight};
  box-sizing: border-box;
  padding: 18px 24px;
  background: ${({ bgImage }) => `url(${bgImage})`};
  background-size: cover;
  border-radius: 6px;
  font-family: 'Staatliches',serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  box-shadow: 0 6px 15px rgba(0,0,0,0.4);

  :after {
    background: url(${eastLogoSmall});
    background-size: contain;
    height: 70px;
    width: 70px;
    content: '';

    right: 10px;
    top: 10px;
    position: absolute;
  }

  @media ${devices.mobile} {
    width: ${props => props.theme.cardWidthMobile};
    height: ${props => props.theme.cardHeightMobile};
    padding: 24px;
  }
`

export const Description = styled.div`
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 2px;
  color: #F8F8F8;
`

export const BottomItem = styled.div<{fontSize?: number, lineHeight?: number}>`
  font-size: ${props => `${props.fontSize || 16}px`};
  line-height: 16px;
  letter-spacing: ${props => `${props.lineHeight || 2}px`};
  color: #F8F8F8;
  font-weight: 300;
  text-transform: uppercase;
`

export const CollateralAssetsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media ${devices.mobile} {
    gap: 6px;
  }
`

export const AssetInfoItem = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  gap: ${props => `${props.gap || 4}px`};
`

export const PlusContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const PlusImage = styled.div`
  margin: 0 auto;
  width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  background-size: 80px;
  background-image: url(${iconPlus});
  transition: transform 250ms;

  :hover {
    transform: scale(1.1);
  }
`

export const AddEast = styled.div`
  font-family: Staatliches;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #FFFFFF;
  text-shadow: 0px 6px 8px rgba(0, 0, 0, 0.25);
`

export const DefaultCardInfoText = styled.div`
  color: rgba(255, 255, 255, 0.4);
  font-size: 20px;
  position: absolute;
  bottom: 15px;
  letter-spacing: 1.5px;
  text-align: center;
`